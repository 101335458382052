import { Component, OnInit, } from "@angular/core";
import { UtentiService } from "src/app/_services/utenti.service";
import * as FileSaver from "file-saver";
import { DatePipe } from "@angular/common";
import { SerramentistaExport } from "src/app/_interfaces/serramentista";
import { ExportUtentiExcel } from "src/app/_interfaces/list-type";

@Component({
    selector: "app-utenti-export",
    templateUrl: "./utenti-export.component.html",
    styleUrls: ["./utenti-export.component.scss"],
})

export class UtentiExportComponent implements OnInit {
    utenti: SerramentistaExport[];
    SelecExpExcel: SerramentistaExport[] = [];
    ExpExcel: ExportUtentiExcel[];
    // ruolo: string;
    cols: any[];

    constructor(
        private UtentiSrv: UtentiService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit(): void {
        // this.ruolo = sessionStorage.getItem("ruolo");
        this.refreshListaUtenti();
    }

    refreshListaUtenti(): void {
        this.UtentiSrv.getExportUtenti().subscribe((res) => {
            this.utenti = [...res.data];
            this.ConvertiPerExport();
        });
    }

    exportExcel(): void {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.ExpExcel);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "Utenti");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName +
            "_Export_" +
            this.datePipe
                .transform(new Date(), "yyyyMMddHHmmss")
                .toString() +
            EXCEL_EXTENSION
        );
    }

    ConvertiPerExport(): void {
        this.ExpExcel = [];
        let rigaEXcel: any = {};
        this.utenti.forEach((item) => {
            if (item.ruolo != 'SA') {
                rigaEXcel.Nome = item.name;
                rigaEXcel.Email = item.email;
                rigaEXcel.Ruolo = item.ruolo
                rigaEXcel.Abilitato = item.abilitato;
                rigaEXcel.Account = item.account;
                rigaEXcel.Account_email = item.account_email;
                // rigaEXcel.Provincia = item.serr_provincia        
                rigaEXcel.Ragione_Sociale = item.serr_ragsoc;
                rigaEXcel.Tipo = item.serr_tipo;
                rigaEXcel.PIva = item.serr_piva;
                rigaEXcel.Cf = item.serr_cf
                rigaEXcel.Registro_Imprese = item.serr_registro_imprese;
                rigaEXcel.Rea = item.serr_rea;
                rigaEXcel.Nazione = item.serr_nazione;
                rigaEXcel.Regione = item.serr_regione
                rigaEXcel.Pr = item.serr_provincia;
                rigaEXcel.Citta = item.serr_citta;
                rigaEXcel.Cap = item.serr_cap;
                rigaEXcel.Indirizzo = item.serr_indirizzo
                rigaEXcel.Civico = item.serr_civico;
                rigaEXcel.Telefono = item.serr_telefono;
                rigaEXcel.Mail = item.serr_email;
                rigaEXcel.Pec = item.serr_pec
                rigaEXcel.Sdi = item.serr_sdi;
                rigaEXcel.Cancellato = item.serr_cancellato;
                rigaEXcel.KO = item.cnt_ko;
                this.ExpExcel.push(rigaEXcel);
                rigaEXcel = {};
            }
        });
    }

    roundN(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}


