import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Offerta, LogStati } from 'src/app/_interfaces/lavori';
import { LavoriService } from 'src/app/_services/lavori.service';
import { environment } from 'src/environments/environment';
import { InterventoStato } from 'src/app/_interfaces/list-type';
import { ListTypeService } from 'src/app/_services/list-type.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HtmlPreviewComponent } from '../html-preview/html-preview.component';


@Component({
    selector: 'app-offerte',
    templateUrl: './offerte.component.html',
    styleUrls: ['./offerte.component.scss']
})

export class OfferteComponent implements OnInit {

    private baseUrl: string = environment.baseUrl;

    offerte: Offerta[];
    logStati: LogStati[];
    interventoStato: InterventoStato[];
    statoOfferta: InterventoStato[]
    strUrl: string;

    environment = environment;
    modalStato: boolean = false;
    modalDown: boolean = false;
    modalCodice: boolean = false;

    frmStato: FormGroup;

    ruolo: string;

    docLavId: number;

    ref: DynamicDialogRef;
    blockedDocument: boolean = false;

    constructor(
        private offerteSrv: LavoriService,
        private breadcrumbService: AppBreadcrumbService,
        private listTypeSrv: ListTypeService,
        private msgService: MessageService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit(): void {
        this.ruolo = sessionStorage.getItem("ruolo");
        this.breadcrumbService.setItems([
            { label: 'Offerte' },
        ]);
        this.creaFormOfferta();
        this.refreshOfferte();
        this.refreshInterventoStati();

    }

    creaFormOfferta() {
        this.frmStato = new FormGroup({
            lav_id: new FormControl(""),
            lav_tipo: new FormControl(""),
            lav_tipo_bc: new FormControl(""),
            lav_user: new FormControl(""),
            lav_cliente: new FormControl(""),
            lav_abitazione: new FormControl(""),
            lav_titolarita: new FormControl(""),
            lav_codice: new FormControl(""),
            lav_descrizione: new FormControl(""),
            lav_tipologia_intervento: new FormControl(""),
            lav_tipologia_agevolazione: new FormControl(""),
            lav_tipologia: new FormControl(""),
            lav_tipo_unita: new FormControl(""),
            lav_tipo_detrazione: new FormControl(""),
            lav_zona_climatica: new FormControl(""),
            lav_prodotto_installato: new FormControl(""),
            lav_mq_installati: new FormControl(""),
            lav_mq_installati_co: new FormControl("0"),
            lav_transmittanza: new FormControl(""),
            lav_iva_offerta: new FormControl(""),
            lav_iva_prestazioni: new FormControl(""),
            lav_iva_attualizzazione: new FormControl(""),
            lav_praticaenea: new FormControl(0),
            lav_costoenea: new FormControl(0),
            lav_pag_desc_1: new FormControl("Saldo"),
            lav_pag_perc_1: new FormControl("100%"),
            lav_pag_desc_2: new FormControl(""),
            lav_pag_perc_2: new FormControl(""),
            lav_pag_desc_3: new FormControl(""),
            lav_pag_perc_3: new FormControl(""),
            lav_consegna: new FormControl("90 giorni"),
            lav_cessionario: new FormControl(""),
            lav_vendita_infissi: new FormControl(0),
            lav_beni_significativi: new FormControl(0),
            lav_altri_beni: new FormControl(0),
            lav_posa_opera: new FormControl(0),
            lav_altre_opere: new FormControl(0),
            lav_prestazioni_prof: new FormControl(0),
            lav_chiusura: new FormControl(""),
            lav_stato: new FormControl(0),
            lav_cancellato: new FormControl(""),
            lav_note: new FormControl(""),
            lav_importo: new FormControl(0),
            lav_asseverazione: new FormControl(""),
            lav_visto: new FormControl(""),
            lav_imp_asseverazione: new FormControl(0),
            lav_file_step2: new FormControl(""),
            lav_max_detraibile: new FormControl(""),
            lav_imp_detraibile_enea: new FormControl(""),
            lav_recupero_fiscale_anno: new FormControl(""),
            lav_finanz: new FormControl(0),
            lav_preventiviAttr: new FormControl(""),
            id: new FormControl(""),
            name: new FormControl(""),
            email: new FormControl(""),
            ruolo: new FormControl(""),
            abilitato: new FormControl(""),
            email_verified_at: new FormControl(""),
            password: new FormControl(""),
            remember_token: new FormControl(""),
            created_at: new FormControl(""),
            updated_at: new FormControl(""),
            cl_id: new FormControl(""),
            cl_serramentista: new FormControl(""),
            cl_tipo: new FormControl(""),
            cl_nome: new FormControl(""),
            cl_data_nascita: new FormControl(""),
            cl_nazione_nascita: new FormControl(""),
            cl_regione_nascita: new FormControl(""),
            cl_citta_nascita: new FormControl(""),
            cl_provincia_nascita: new FormControl(""),
            cl_telefono: new FormControl(""),
            cl_email: new FormControl(""),
            cl_pec: new FormControl(""),
            cl_cf: new FormControl(""),
            cl_piva: new FormControl(""),
            cl_cancellato: new FormControl(""),
            cl_note: new FormControl(""),
            ab_id: new FormControl(""),
            ab_cliente: new FormControl(""),
            ab_provincia: new FormControl(""),
            ab_citta: new FormControl(""),
            ab_indirizzo: new FormControl(""),
            ab_civico: new FormControl(""),
            ab_cap: new FormControl(""),
            ab_codice_comune: new FormControl(""),
            ab_foglio: new FormControl(""),
            ab_mappale: new FormControl(""),
            ab_mq_immobile: new FormControl(""),
            ab_mq_infissi: new FormControl(""),
            ab_categoria_catastale: new FormControl(""),
            ab_subalterno: new FormControl(""),
            ab_titolarita: new FormControl(0),
            ab_cancellata: new FormControl(""),
            ab_data_aggiunta: new FormControl(""),
            ab_data_modifica: new FormControl(""),
            ti_id: new FormControl(""),
            ti_alias: new FormControl(""),
            ti_nome: new FormControl(""),
            tin_id: new FormControl(""),
            tin_alias: new FormControl(""),
            tin_nome: new FormControl(""),
            ta_id: new FormControl(""),
            ta_alias: new FormControl(""),
            ta_nome: new FormControl(""),
            zc_codice: new FormControl(""),
            pi_id: new FormControl(""),
            pi_alias: new FormControl(""),
            pi_nome: new FormControl(""),
            cf_id: new FormControl(""),
            cf_alias: new FormControl(""),
            cf_nome: new FormControl(""),
            lat_id: new FormControl(""),
            lat_prev_id: new FormControl(0),
            lat_lavoro: new FormControl(""),
            lat_tipo: new FormControl(""),
            lat_oneri: new FormControl(""),
            lat_detrazione: new FormControl(""),
            lat_cessionario: new FormControl(""),
            lat_chiusura_pratica: new FormControl(""),
            lat_range_min: new FormControl(""),
            lat_range_max: new FormControl(""),
            lat_costo_fisso: new FormControl(""),
            lat_imp: new FormControl(""),
            lat_costo_min: new FormControl(""),
            lat_costo_max: new FormControl(""),
            lat_oneri_att: new FormControl(""),
            lat_costo_asseverazione: new FormControl(""),
            lat_costo_visto: new FormControl(""),
            lat_perc_asseverazione: new FormControl(""),
            lat_perc_visto: new FormControl(""),
        });
    }

    refreshOfferte(): void {
        if (this.ruolo != "SE") {
            this.offerteSrv.getInterventiTipo().subscribe((res) => {
                this.offerte = [...res.data];
            });
        } else {
            this.offerteSrv.getInterventiTipoByUser(sessionStorage.getItem("id")).subscribe((res) => {
                this.offerte = [...res.data];
            });
        }
    }

    refreshLogStati(idOfferta: string): void {
        this.offerteSrv.getLogStati(idOfferta).subscribe((res) => {
            this.logStati = [...res.data];
        });
    }

    refreshOfferta(id: string): void {
        this.offerteSrv.getInterventoID(id).subscribe((res) => {
            console.log("LEGGO res", res.data)
            this.frmStato.patchValue(res.data);
            console.log("LEGGO FRM", this.frmStato)
        });
    }

    refreshInterventoStati(): void {
        this.listTypeSrv.getInterventoStati().subscribe((res) => {
            this.interventoStato = [...res.data];
        });
    }

    Submit(): void {
        this.blockedDocument = true;
        this.modalCodice = false;
        console.log("SCRIVO FRM", this.frmStato)
        this.offerteSrv.addInterventoOFFERTA(this.frmStato.value).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'danger',
                summary: `Sato Offerta`,
                detail: res.res === 'ok' ? 'Stato Salvato' : 'Errore in fase di Salvataggio',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.blockedDocument = false;
            this.CloseModal();
            this.refreshOfferte();
        });
    }

    submitCodice(id: number, codice: string):void {
        this.offerteSrv.upCodiceManuale(id,codice).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'danger',
                summary: `Sato Offerta`,
                detail: res.res === 'ok' ? 'Stato Salvato' : 'Errore in fase di Salvataggio',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.blockedDocument = false;
            this.CloseModal();
            this.refreshOfferte();
        });
    }

    OpenModal(idOfferta: string, idOffertaStato: number): void {
        this.modalStato = true;
        if (this.ruolo != "SA") {
            this.statoOfferta = this.interventoStato.filter((s) => s.is_id >= idOffertaStato)
        } else {
            this.statoOfferta = this.interventoStato
        }

        this.refreshLogStati(idOfferta);
        this.creaFormOfferta();
        this.refreshOfferta(idOfferta);
    }

    CloseModal(): void {
        this.modalStato = false;
    }

    openModalDown(id, stato) {
        this.docLavId = id;
        this.ref = this.dialogService.open(HtmlPreviewComponent, {
            width: '70%',
            contentStyle: { "overflow": "auto" },
            baseZIndex: 10000,
            data: { id: id, stato: stato }
        });

        this.ref.onClose.subscribe(() => {
            this.refreshOfferte();
        });
    }

    openModalCodice(id) {
        this.creaFormOfferta();
        this.refreshOfferta(id);
        this.modalCodice = true;
    }

    CloseModalCodice():void {
        this.modalCodice = false;
    }

    BadgeColor(stato: number): string {
        if (stato === 1) {
            return "info";
        } else if (stato === 2) {
            return "warning";
        } else if (stato === 3) {
            return "success";
        } else {
            return "danger";
        }
    }

    @ViewChild('dt', { static: false }) dt;
    @ViewChild('searchField', { static: false }) searchField;

    RemoveFind(): void {
        this.dt.reset();
        this.searchField.value = "";
        sessionStorage.removeItem("stateOfferte-session")
    }


}
