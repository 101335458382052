import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Clienti, Cliente } from '../_interfaces/clienti';

@Injectable({
  providedIn: 'root'
})
export class ClientiService {
  private baseUrl: string = environment.baseUrl;
  editUserValue: boolean = false;

  constructor(private http: HttpClient) { }

  getClienti(): Observable<{ data: Clienti[] }> {
    return this.http.get(`${this.baseUrl}/clienti`).pipe(
      map((res: { data: Clienti[] }) => res)
    );
  }

  getClientiByUser(idUser: string): Observable<{ data: Clienti[] }> {
    return this.http.get(`${this.baseUrl}/clienti/serr/${idUser}`).pipe(
      map((res: { data: Clienti[] }) => res)
    );
  }

  getCliente(idCliente: string): Observable<Cliente> {
    return this.http.get(`${this.baseUrl}/clienti/${idCliente}`).pipe(
      map((res: { data: Clienti }) => {
        res.data[0].documento = res.data[0].documenti[0] ?? {};
        res.data[0].legale = res.data[0].rappr_legale[0] ?? {};
        res.data[0].residenza = res.data[0].residenze[0] ?? {};
        res.data[0].offerte = res.data[0].cnt_offerte_approvate[0] ?? {};
        return res.data[0];
      })
    );
  }

  addCliente(cliente: Cliente): Observable<{res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/clienti/add`, cliente ).pipe(
      map((res: { res: string, message: string }) => res));
  }

  delCliente(idCliente: number, azione: boolean): Observable<{res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/clienti/del`, { id: idCliente, cancellato: azione }).pipe(
      map((res: { res: string, message: string }) => res));
  }

  editUser(value: boolean){
    this.editUserValue = value;
  }

}
