import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LavoriTutti, Lavori, Offerta, LogStati } from '../_interfaces/lavori';

@Injectable({
    providedIn: 'root'
})
export class LavoriService {

    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    getInterventoTutti(): Observable<{ data: LavoriTutti[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte`).pipe(
            map((res: { data: LavoriTutti[] }) => res)
        );
    }

    getInterventiCliente(idCliente: string): Observable<{ data: LavoriTutti[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/cliente/${idCliente}`).pipe(
            map((res: { data: LavoriTutti[] }) => res)
        );
    }

    getInterventiTipo(): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/tipo/offerta`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getExportInterventiTipo(): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/tipo/offerta_export/offerta`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getInterventiTipoByUser(idUtente: string): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/user_tipo/${idUtente}/offerta`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getExportInterventiTipoByUser(idUtente: string): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/user_tipo/offerta_export/${idUtente}/offerta`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getInterventoID(idIntervento: string): Observable<{ data: Offerta }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/${idIntervento}`).pipe(
            map((res: { data: Offerta }) => ({ data: res.data[0] }))
        );
    }

    getInterventoStato(stato: number, ruolo: string): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/stato/${stato}/${ruolo}`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getInterventoStatoByUser(stato: number, idUtente: string, ruolo: string): Observable<{ data: Offerta[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/user_stato/${idUtente}/${stato}/${ruolo}`).pipe(
            map((res: { data: Offerta[] }) => res)
        );
    }

    getLogStati(idOfferta: string): Observable<{ data: LogStati[] }> {
        return this.http.get(`${this.baseUrl}/interventi_offerte/log/${idOfferta}`).pipe(
            map((res: { data: LogStati[] }) => res)
        );
    }

    addIntervento(lavoro: Lavori): Observable<{ res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/interventi_offerte/add`, lavoro).pipe(
            map((res: { res: string, message: string }) => res));
    }

    addInterventoOFFERTA(lavoro: Offerta): Observable<{ res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/interventi_offerte/add`, lavoro).pipe(
            map((res: { res: string, message: string }) => res));
    }

    upCodiceManuale(id: number, codice:string): Observable<{ res: string, message: string }> {
        let cod : {"lav_id": number, "lav_codice": string} = {"lav_id":id, "lav_codice": codice}
        return this.http.post(`${this.baseUrl}/interventi_offerte/up_codice_manuale`, cod).pipe(
            map((res: { res: string, message: string }) => res));
    }

    delIntervento(idIntervento: number, azione: boolean, stato: number): Observable<{ res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/interventi_offerte/del`, {
            lav_id: idIntervento,
            lav_cancellato: azione,
            lav_stato: stato
        }).pipe(
            map((res: { res: string, message: string }) => res)
        );
    }

}
