import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Messaggi, MessaggiAdmin } from '../_interfaces/messaggi';

@Injectable({
  providedIn: 'root'
})
export class MessaggiService {
  private baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getMessaggiByLavoro(idLavoro: string): Observable<{ data: Messaggi[] }> {
    return this.http.get(`${this.baseUrl}/messaggi/get_lavoro/${idLavoro}`).pipe(
      map((res: { data: Messaggi[] }) => res)
    );
  }

  getMessaggiAdminByLavoro(idLavoro: string): Observable<{ data: MessaggiAdmin[] }> {
    return this.http.get(`${this.baseUrl}/messaggi_admin/get_lavoro/${idLavoro}`).pipe(
      map((res: { data: MessaggiAdmin[] }) => res)
    );
  }

  getNrMessaggiNONLetti(idLavoro: string): Observable<{ cnt: number }> {
    return this.http.get(`${this.baseUrl}/messaggi/cnt_nl/${idLavoro}`).pipe(
      map((res: { cnt: number }) => res)
    );
  }

  addMessaggio(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi/add`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  addMessaggioAdmin(messaggio: MessaggiAdmin): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi_admin/add`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  updMessaggiLetti(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi/up_lavoro`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  updMessaggioNONLetto(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi/up_messaggio`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  cancMEssaggio(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi/del_messaggio`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }



}
