import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { GeneraForm } from "src/app/_helpers/genera-form";
import { Utility } from "src/app/_helpers/utility";
import { Abitazioni } from "src/app/_interfaces/abitazioni";
import { Cliente } from "src/app/_interfaces/clienti";
import { Catastale, TitolaritaImmobile } from "src/app/_interfaces/list-type";
import { AbitazioniService } from "src/app/_services/abitazioni.service";
import { ListTypeService } from "src/app/_services/list-type.service";
import { Nazioni, Regioni, Citta } from "src/app/_interfaces/nazioni";
import { NazioniService } from "src/app/_services/nazioni.service";
import { AutoComplete } from "primeng/autocomplete";
import _ from "lodash";

@Component({
    selector: "abitazione-select",
    templateUrl: "./abitazione-select.component.html",
    styleUrls: ["./abitazione-select.component.scss"],
})
export class AbitazioneSelectComponent implements OnInit {
    @Input() cliente: Cliente;
    @Input() abitazione: Abitazioni;
    @Input() editabile: boolean;

    @Output() abitazioneChange = new EventEmitter<Abitazioni>();

    form!: FormGroup;
    abitazioni: Abitazioni[] = [];
    categoriaCatastale: Catastale[];
    titImmobile: TitolaritaImmobile[];

    modalAbitazione = false;
    frmAbitazione: FormGroup;
    frmIsNew = true;

    @ViewChild("dt", { static: false }) dt: Table;

    nazioni: Nazioni[] = [];
    regioni: Regioni[] = [];
    citta: Citta[] = [];
    nazioniSuggestion: any[] = [];
    regioniSuggestion: any[] = [];
    cittaSuggestion: any[] = [];
    @ViewChild("ab_regione", { static: false })
    ab_regione: AutoComplete;
    @ViewChild("ab_citta", { static: false })
    ab_citta: AutoComplete;

    constructor(
        private abitazioneSrv: AbitazioniService,
        private msgService: MessageService,
        private confirmService: ConfirmationService,
        private listTypeSrv: ListTypeService,
        private rootFormGroup: FormGroupDirective,
        private nazioniSrv: NazioniService
    ) {}

    ngOnInit(): void {
        this.refreshRegioni();
        //this.abitazione = null;
        this.loadAbitazioni();
        this.loadCategoriaCAtastale();
        this.loadTitolariImmobili();
        this.creaFormAbitazione();

        this.form = this.rootFormGroup.control.get(
            "abitazioniForm"
        ) as FormGroup;
    }

    loadAbitazioni(): void {
        var id = this.rootFormGroup.control.controls["anagraficaForm"].get("prev_cliente").value;
        this.abitazioneSrv.getAbitazioni(id).subscribe((res) => {this.abitazioni = [...res.data];});
    }

    settaRegione(regione: string, citta: string): void {
        this.nazioniSrv
            .getCittaByRegione(this.abitazioni[0].ab_regione)
            .subscribe((res) => {
                this.citta = [...res.data];
                this.ab_regione.inputEL.nativeElement.value = regione;
                this.ab_citta.inputEL.nativeElement.value = citta;
            });
    }

    refreshRegioni(): void {
        this.nazioniSrv
            .getRegioni()
            .subscribe((res) => (this.regioni = [...res.data]));
    }

    refreshCitta(ev, key: string, section: string = ""): void {
        const regione: string = ev.value;
        if (_.isEmpty(regione)) {
            return;
        }
        this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
            this.citta = [...res.data];
        });
        this.frmAbitazione.controls[key].setValue(regione);
        this.ab_regione.inputEL.nativeElement.value = regione;
    }

    search(ev, type): void {
        const query: string = ev.query;
        switch (true) {
            case type === "regioni":
                this.regioniSuggestion = [
                    ...this.regioni.filter(
                        (r: Regioni) =>
                            r.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
            case type === "citta":
                this.cittaSuggestion = [
                    ...this.citta.filter(
                        (c: Citta) =>
                            c.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
        }
    }

    selectProvincia(ev: Citta, key: string, section: string = ""): void {
        this.frmAbitazione.controls[key].setValue(ev.value);
        this.ab_citta.inputEL.nativeElement.value = ev.value;
        this.frmAbitazione.controls["ab_provincia"].setValue(ev.provincia);
    }

    resetDatiAbitazione(): void {
        this.frmAbitazione.controls.ab_regione.reset();
        this.frmAbitazione.controls.ab_citta.reset();
        this.frmAbitazione.controls.ab_provincia.reset();
    }

    resetDatiResidenza(): void {
        this.frmAbitazione.controls.ab_regione.reset();
        this.frmAbitazione.controls.ab_citta.reset();
        this.frmAbitazione.controls.ab_provincia.reset();
    }

    loadCategoriaCAtastale(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categoriaCatastale = [...res.data];
        });
    }

    loadTitolariImmobili(): void {
        this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
            this.titImmobile = [...res.data].filter(
                (item) => item.ti_id != 6 && item.ti_id != 18
            );
        });
    }

    selectAbitazione(ab: Abitazioni) {
        this.abitazione = ab;
        this.abitazioneChange.emit(this.abitazione);
        this.form.controls.prev_abitazione.setValue(ab.ab_id);
    }

    clearAbitazione() {
        this.abitazione = null;
        this.abitazioneChange.emit(this.abitazione);
        this.form.controls.prev_abitazione.setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_intervento"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_intervento")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipo_unita"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipo_unita")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_descrizione"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_descrizione")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_chiusura"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_chiusura")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_zona_climatica"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_zona_climatica")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_prodotto_installato"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_prodotto_installato")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_mq_installati"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_mq_installati")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_mq_installati_co"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_mq_installati_co")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_transmittanza"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_transmittanza")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_agevolazione"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_agevolazione")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_interventoBC"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_interventoBC")
                .setValue("");
    }

    creaFormAbitazione() {
        var id =
            this.rootFormGroup.control.controls["anagraficaForm"].get(
                "prev_cliente"
            ).value;
        this.frmAbitazione = GeneraForm.createFormAbitazione(id);
        this.frmAbitazione.controls.ab_codice_comune.setValue("H501");
        this.frmAbitazione.controls.ab_categoria_catastale.setValue(2);
        this.frmAbitazione.controls.ab_foglio.setValue("000");
        this.frmAbitazione.controls.ab_mappale.setValue("000");
        this.frmAbitazione.controls.ab_subalterno.setValue("000");
        this.frmAbitazione.controls.ab_mq_immobile.setValue("0");
        this.frmAbitazione.controls.ab_titolarita.setValue(1);
    }

    private showAbitazioneDialog(): void {
        this.modalAbitazione = true;
        Utility.updateFormValidity(this.frmAbitazione);
    }

    showNewAbitazioneDialog() {
        this.frmIsNew = true;
        this.creaFormAbitazione();
        this.showAbitazioneDialog();
    }

    showEditAbitazioneDialog(abitazione: Abitazioni): void {
        this.frmIsNew = false;
        // this.OffeteApprovateAbitazione = abitazione.cnt_offerte_approvate.cnt
        this.frmAbitazione.patchValue(abitazione);
        this.settaRegione(abitazione.ab_regione, abitazione.ab_citta);
        this.showAbitazioneDialog();
        // if (this.ruolo === "SE") {
        //     this.enabledSerr = false;
        // } else {
        //     this.enabledSerr = true;
        // }
    }

    ConcideResidenza(): void {
        const residenza =
            this.cliente.residenza ||
            ((this.cliente as any).residenze &&
                (this.cliente as any).residenze[0]);
        if (residenza) {
            this.frmAbitazione.controls.ab_indirizzo.setValue(
                residenza.r_indirizzo
            );
            this.frmAbitazione.controls.ab_civico.setValue(residenza.r_civico);
            this.frmAbitazione.controls.ab_regione.setValue(
                residenza.r_regione
            );
            this.frmAbitazione.controls.ab_citta.setValue(residenza.r_citta);
            this.frmAbitazione.controls.ab_provincia.setValue(
                residenza.r_provincia
            );
            this.frmAbitazione.controls.ab_cap.setValue(residenza.r_cap);
        }
    }

    saveAbitazione(): void {
        this.abitazioneSrv
            .addAbitazioni(this.frmAbitazione.value)
            .subscribe((res) => {
                this.msgService.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "danger",
                    summary: `Abitazione`,
                    detail: res.message,
                    life: 8000, // Tempo in millisecondi (10 secondi)
                });
                this.loadAbitazioni();
                this.creaFormAbitazione();
                this.modalAbitazione = false;
            });
    }

    delAbitazione(): void {
        this.confirmService.confirm({
            header: `Elimina Abitazione`,
            message: "Sicuro di Eseguire l'operazione di Cancellazione?",
            icon: "pi  pi-question-circle",
            accept: () => {
                this.abitazioneSrv
                    .delAbitazioni(
                        this.frmAbitazione.controls["ab_id"].value,
                        true
                    )
                    .subscribe((res) => {
                        this.msgService.add({
                            key: "tst",
                            severity: res.res === "ok" ? "success" : "danger",
                            summary: `Elimina Abitazione`,
                            detail: res.message,
                            life: 8000, // Tempo in millisecondi (10 secondi)
                        });
                        this.modalAbitazione = false;
                        this.loadAbitazioni();
                    });
            },
        });
    }

    cancelModale(): void {
        this.modalAbitazione = false;
        this.creaFormAbitazione();
    }
}
