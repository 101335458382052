import { Component, OnInit } from '@angular/core';
import { UploadService } from 'src/app/_services/upload.service';
import { AllegatoArchivio } from 'src/app/_interfaces/allegato';
import { ArchivioCategoria } from 'src/app/_interfaces/list-type';
import { AllegatiService } from 'src/app/_services/allegati.service';
import { ArchivioCategoriaService } from 'src/app/_services/archivio-categoria.service';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-archivio',
  templateUrl: './archivio.component.html',
  styleUrls: ['./archivio.component.scss']
})
export class ArchivioComponent implements OnInit {
  upFile: File;
  allegati: AllegatoArchivio[];
  archCategoria: ArchivioCategoria[];
  tipoAllegatoValue: string;
  tipoVisioneValue: string;
  ruoloUtente: string;

  environment = environment;
  baseUrl: string = environment.baseUrl;

  allegatoTipoVisione: { label: string; value: any }[] = [
    { label: "SI", value: '0' },
    { label: "SSCO", value: '2' },
    { label: "SI + SSCO", value: '1' },
  ];

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private msgService: MessageService,
    private uploadSrv: UploadService,
    private messageSrv: MessageService,
    private allegatoSrv: AllegatiService,
    private archCatSrv: ArchivioCategoriaService,
    private confirmService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: `Archivio`, }]);
    this.ruoloUtente = sessionStorage.getItem("ruolo");
    this.tipoVisioneValue = '0';
    this.refreshArchCategoria();
    this.refreshAllegati();
  }

  refreshAllegati(): void {
    this.allegatoSrv.getAllegatiArchivio(sessionStorage.getItem("id")).subscribe((res) => {
      this.allegati = [...res.data];
    });
  }

  refreshArchCategoria(): void {
    this.archCatSrv.getArchivioCaterorie().subscribe((res) => {
      this.archCategoria = [...res.data];
      if (res.data.length != 0) {
        this.tipoAllegatoValue = res.data[0].acat_nome;
      }
    });
  }

  delAllegatoArchivio(idFileArchivio: number): void {
    let archFile: { arc_id: number } = { arc_id: 0 };
    archFile.arc_id = idFileArchivio;
    this.confirmService.confirm({
      header: `Elimina File`,
      message: "Sicuro di Eliminare il File Selezionato?",
      icon: "pi  pi-question-circle",
      accept: () => {
        this.allegatoSrv.delAllegatoArchivio(archFile).subscribe((res) => {
          this.messageSrv.add({
            key: 'tst',
            severity: 'info',
            summary: `Cancella File`
          });
          this.refreshAllegati();
        })
      },
    });
  }

  onUpload(event, fu): void {

    let allegato: AllegatoArchivio = {};

    for (let file of event.files) {
      this.upFile = file;
    }

    this.uploadSrv.uploadAllegatoArchivio(this.upFile).subscribe((res) => {
      allegato.arc_tabella = 'admin';
      allegato.arc_campo = 'adm_user';
      allegato.arc_campo_id = Number(sessionStorage.getItem("id"));
      allegato.arc_nome = res.nome_file;
      allegato.arc_descrizione = this.tipoAllegatoValue;
      allegato.arc_interventi = this.tipoVisioneValue;
      allegato.arc_path = 'files/';
      this.allegatoSrv.addAllegatoArchivio(allegato).subscribe((resAllegato) => {
        this.refreshAllegati();
        this.messageSrv.add({
          key: 'tst',
          severity: 'info',
          summary: `File Caricato`,
          detail: res.nome_file,
          life: 8000 // Tempo in millisecondi (10 secondi)
        });
      });
    });

    fu.clear();
  }

}
