import {Component, OnInit} from '@angular/core';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';
import {Clienti} from 'src/app/_interfaces/clienti';
import {Serramentista} from 'src/app/_interfaces/serramentista';
import {ClientiService} from 'src/app/_services/clienti.service';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import { SerramentistaService } from 'src/app/_services/serramentista.service';

@Component({
    selector: 'app-cessionario-finale-list.component',
    templateUrl: './cessionario-finale-list.component.html',
    styleUrls: ['./cessionario-finale-list.component.scss']
})

export class CessionarioFinaleListComponent implements OnInit {

    cessionarioFinale: Serramentista[];

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private serramentistaSrv: SerramentistaService) {
        this.breadcrumbService.setItems([{label: 'Cessionari Finali'}]);
    }

    clear(table: Table) {
        table.clear();
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh(): void {       
            this.serramentistaSrv.getCessionarifinali().subscribe((res) => {
                this.cessionarioFinale = [...res.data];
            });
    }
}
