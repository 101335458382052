import {Component} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-html-preview',
    templateUrl: './html-preview-step5.component.html',
    styleUrls: ['./html-preview-step5.component.scss']
})

export class HtmlPreviewStep5Component {

    public lavId: number = 0;
    public lavNome: string = '';
    private baseUrl: string = environment.baseUrl;
    public htmlText: string;
    public downIsDisable: boolean = true;

    constructor(private http: HttpClient, private config: DynamicDialogConfig) {
        this.lavId = this.config.data.id;
        this.lavNome = this.config.data.nome;  
        this.getHtml().subscribe(
            data => {
                if (this.isJson(data)) {
                    let jp = JSON.parse(data);
                    this.htmlText = jp.message;
                } else {
                    this.downIsDisable = false;
                    this.htmlText = data
                }
            }
        )
    }

    private isJson(json: string): boolean {
        try {
            JSON.parse(json);
        } catch (e) {
            return false;
        }
        return true;
    }

    getHtml(): Observable<any> {
        let url = this.baseUrl + '/docx/doc2step5_html/' + this.lavId + '/' + this.lavNome;
        return this.http.get(url, {responseType: 'text'});
    }

    downloaDocumento(): void {
        // this.modalDown = false;
        window.location.href = this.baseUrl +  '/docx/doc2step5_pdf/' + this.lavId + '/' + this.lavNome;
    }

}
