import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class RoleGuard implements CanActivate {

    ROLES = {
        SuperAdmin: 2,
        Admin: 1,
        Serramentista: 0,
    };

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const ruolo = (sessionStorage.getItem('ruolo') === 'SA' ? 'SuperAdmin' : (sessionStorage.getItem('ruolo') === 'AD' ? 'Admin' : 'Serramentista'));
        if (!(this.ROLES[next.data.minRole] <= this.ROLES[ruolo])) {
            return false;
        }
        return true;
    }

}
