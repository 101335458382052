import { Component, OnInit, } from "@angular/core";
import { Offerta, Preventivo } from "src/app/_interfaces/lavori";
import { LavoriService } from "src/app/_services/lavori.service";
import * as FileSaver from "file-saver";
import { ExportLavoriExcel } from "src/app/_interfaces/list-type";
import { AppBreadcrumbService } from "src/app/app.breadcrumb.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import { SerramentistaService } from "src/app/_services/serramentista.service";

@Component({
    selector: 'app-lavori-export',
    templateUrl: './lavori-export.component.html',
    styleUrls: ['./lavori-export.component.scss']
})
export class LavoriExportComponent implements OnInit {

    offerte: Offerta[];
    SelecExpExcel: Offerta[] = [];

    environment = environment;
    ExpExcel: ExportLavoriExcel[];
    titoloGriglia: string;
    ruolo: string;
    // exportColumns: any[];
    cols: any[];

    cessionarioFinale: { label: string; value: number }[] = [];

    constructor(
        private offerteSrv: LavoriService,
        private datePipe: DatePipe,
        private breadcrumbService: AppBreadcrumbService,
        private route: ActivatedRoute,
        private SerramentistaSrv: SerramentistaService,
    ) { }

    ngOnInit(): void {
        this.ruolo = sessionStorage.getItem("ruolo");
        this.refreshCessionariFinali();
    }

    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [];
            [...res.data].forEach((item) => {
                this.cessionarioFinale.push({ label: item.serr_ragsoc, value: item.serr_id })

                if (this.route.snapshot.paramMap.get("tipo") == "o") {
                    this.breadcrumbService.setItems([{ label: "Export Offerte" }]);
                    this.titoloGriglia = "OFFERTE";
                    this.refreshOfferte();
                } else {
                    this.titoloGriglia = "LAVORI";
                    this.breadcrumbService.setItems([{ label: "Export Lavori" }]);
                    this.refreshLavoriPratiche();
                }

            })
        });

    }


    refreshOfferte(): void {
        this.offerte = [];
        if (this.ruolo != "SE") {
            this.offerteSrv.getExportInterventiTipo().subscribe((res) => {
                this.offerte = [...res.data];
                this.ConvertiPerExport();
            });
        } else {
            this.offerteSrv
                .getExportInterventiTipoByUser(sessionStorage.getItem("id"))
                .subscribe((res) => {
                    this.offerte = [...res.data];
                    this.ConvertiPerExport();
                });
        }
    }

    refreshLavoriPratiche(): void {
        this.offerte = [];
        if (this.ruolo != "SE") {
            this.offerteSrv
                .getInterventoStato(3, this.ruolo).subscribe((res) => {
                    this.offerte = [...res.data];
                    this.ConvertiPerExport();
                });
        } else {
            this.offerteSrv
                .getInterventoStatoByUser(3, sessionStorage.getItem("id"),
                    this.ruolo
                )
                .subscribe((res) => {
                    this.offerte = [...res.data];
                    this.ConvertiPerExport();
                });
        }
    }

    exportExcel(): void {
        import("xlsx").then((xlsx) => {
            const exp: any[] =
                this.SelecExpExcel.length > 0
                    ? this.SelecExpExcel
                    : this.ExpExcel;
            const worksheet = xlsx.utils.json_to_sheet(exp);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, this.titoloGriglia);
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName +
            "_export_" +
            this.datePipe
                .transform(new Date(), "yyyyMMddHHmmss")
                .toString() +
            EXCEL_EXTENSION
        );
    }

    ConvertiPerExport(): void {
        this.ExpExcel = [];
        let rigaEXcel: any = {};
        if (this.route.snapshot.paramMap.get("tipo") != "o") {
            this.offerte.sort((a, b) => (a.lav_data_modifica > b.lav_data_modifica ? -1 : 1));
        } else {
            this.offerte.sort((a, b) => (a.lav_data_aggiunta > b.lav_data_aggiunta ? -1 : 1));
        }

        this.offerte.forEach((item) => {
            let prObj: Preventivo = JSON.parse(item.lav_preventivo.toString());


            rigaEXcel.Tipo_detrazione = item.lav_codice.includes("_DD_") ? "Detrazione Diretta" : "Sconto in Fattura";
            rigaEXcel.Codice = item.lav_codice;
            rigaEXcel.Serramentista = item.name;
            rigaEXcel.Stato =
                item.lav_stato == 1
                    ? "Elaborata"
                    : item.lav_stato == 2
                        ? "Presentata"
                        : item.lav_stato == 3
                            ? "Approvata"
                            : "KO";

            let strValidato: string;
            let chiusura = item.lat_chiusura_pratica === null ? 7 : Number(item.lat_chiusura_pratica);
            if (typeof (item.step_numero) !== 'undefined') {
                if (this.titoloGriglia == "OFFERTE") {
                    strValidato = "";
                } else if (item.step_numero == null) {
                    strValidato = "da inserire Step 1";
                } else if (item.step_numero == chiusura) {
                    strValidato = "Ultimato";
                } else {
                    strValidato = "Validato step " + item.step_numero;
                }
                rigaEXcel.stepNum =
                    item.step_numero + 1 === chiusura + 1 ? chiusura : item.step_numero + 1;
            }
            if (this.route.snapshot.paramMap.get("tipo") != "o") {
                rigaEXcel.step = strValidato;
            }

            if (this.route.snapshot.paramMap.get("tipo") == "o") {
                rigaEXcel.Giorno_Creazione = new Date(item.lav_data_aggiunta).getDate();
                rigaEXcel.Mese_Creazione = new Date(item.lav_data_aggiunta).getMonth() + 1;
                rigaEXcel.Anno_Creazione = new Date(item.lav_data_aggiunta).getFullYear();
            } else {
                rigaEXcel.Giorno_Modifica = new Date(item.lav_data_modifica).getDate();
                rigaEXcel.Mese_Modifica = new Date(item.lav_data_modifica).getMonth() + 1;
                rigaEXcel.Anno_Modifica = new Date(item.lav_data_modifica).getFullYear();
                if (item.step_numero == chiusura) {
                    rigaEXcel.Giorno_Avanzamento = new Date(item.sr_modifica).getDate();
                    rigaEXcel.Mese_Avanzamento = new Date(item.sr_modifica).getMonth() + 1;
                    rigaEXcel.Anno_Avanzamento = new Date(item.sr_modifica).getFullYear();
                } else {
                    rigaEXcel.Giorno_Avanzamento = new Date(item.max_data_aggiunta).getDate();
                    rigaEXcel.Mese_Avanzamento = new Date(item.max_data_aggiunta).getMonth() + 1;
                    rigaEXcel.Anno_Avanzamento = new Date(item.max_data_aggiunta).getFullYear();
                }

            }

            rigaEXcel.Cliente = item.cl_cognome + " " + item.cl_nome;
            rigaEXcel.Provincia = item.ab_provincia;



            if (item.lav_tipo_detrazione == "Bonus Casa" || item.lav_tipo_detrazione == "BC_SI" || item.lav_tipo_detrazione == "BC_AI") {
                rigaEXcel.Tipologia_bonus = 'BC'

            }
            else if (item.lav_tipo_detrazione == "BA_AI") {
                rigaEXcel.Tipologia_bonus = 'BA'
            }
            else if (item.lav_tipo_detrazione == "ECO Bonus" || item.lav_tipo_detrazione == "SSCO" || item.lav_tipo_detrazione == "SI") {
                rigaEXcel.Tipologia_bonus = 'EB'
            } else {
                rigaEXcel.Tipologia_bonus = item.lav_tipo_detrazione
            }




            rigaEXcel.ID_intervento = item.lav_tipologia;


            rigaEXcel.Tipologia_di_intervento = item.tin_nome;

            if (this.cessionarioFinale.length != 0) {
                if (item.lat_cessionario) {
                    rigaEXcel.Cessionario_finale = this.cessionarioFinale.find((x) => x.value == item.lat_cessionario)?.label;
                }
                if (rigaEXcel.Cessionario_finale =='' || rigaEXcel.Cessionario_finale == undefined || rigaEXcel.Cessionario_finale == null){
                    rigaEXcel.Cessionario_finale =this.cessionarioFinale.find((x) => x.value == Number(item.lav_cessionario))?.label;
                }
            } else {
                rigaEXcel.Cessionario_finale = "Schueco PWS Italia";
            }


            let vendita_infissi = isNaN(Number(item.lav_vendita_infissi)) ? 0 : this.roundN(Number(item.lav_vendita_infissi));
            let posa_opera = isNaN(Number(item.lav_posa_opera)) ? 0 : this.roundN(Number(item.lav_posa_opera));
            let beni_significativi = isNaN(Number(item.lav_beni_significativi)) ? 0 : this.roundN(Number(item.lav_beni_significativi));
            let altri_beni = isNaN(Number(item.lav_altri_beni)) ? 0 : this.roundN(Number(item.lav_altri_beni));
            let altre_opere = isNaN(Number(item.lav_altre_opere)) ? 0 : this.roundN(Number(item.lav_altre_opere));
            let prestazioni_prof = isNaN(Number(item.lav_prestazioni_prof)) ? 0 : this.roundN(Number(item.lav_prestazioni_prof));


            let imponibile_serramentista: number = 0;

            if (item.lav_codice.includes("_DD_")) {
                if (item.lav_codice.includes("_SSCO")) {
                    imponibile_serramentista = vendita_infissi + beni_significativi + posa_opera + altre_opere + altri_beni + prestazioni_prof
                } else {
                    imponibile_serramentista = vendita_infissi + posa_opera + altre_opere + altri_beni + prestazioni_prof
                }
            } else {
                imponibile_serramentista = isNaN(prObj.imponibileSerramentista) ? 0 : this.roundN(prObj.imponibileSerramentista);
            }

            rigaEXcel.Imponibile_fornitore = imponibile_serramentista

            var speseTecniche = 0
            if (item.lav_codice.includes("_DD_")) {
                speseTecniche = Number(prObj.fattura.totale.imponibile) - Number(imponibile_serramentista)
            } else {
                speseTecniche = Number(prObj.speseTecniche)
            }

            var imponibile_totale = 0
            if (item.lav_codice.includes("_DD_")) {
                imponibile_totale = prObj.fattura.totale.imponibile;
                // speseTecniche = Number(prObj.fattura.totale.imponibile) - Number(imponibile_serramentista)
            } else {
                // speseTecniche = isNaN(prObj.speseTecniche) ? 0 : this.roundN(prObj.speseTecniche);
                imponibile_totale = speseTecniche + imponibile_serramentista;
            }

            rigaEXcel.Spese_tecniche = this.roundN(speseTecniche);

            rigaEXcel.imponibile_totale = isNaN(imponibile_totale) ? 0 : this.roundN(imponibile_totale);

            const iva_tot =
                item.lav_codice.includes("_DD_")
                    ? Number(prObj.fattura.totale.iva_10) + Number(prObj.fattura.totale.iva_22)
                    : Number(item.lav_importo.toString()) - (speseTecniche + imponibile_serramentista);
            rigaEXcel.iva_totale = isNaN(iva_tot) ? 0 : this.roundN(iva_tot);


            rigaEXcel.Totale_fattura_Iva_compresa = this.roundN(Number(item.lav_importo.toString()));
            rigaEXcel.Costi_di_attualizzazione = prObj.costiAttuazione == undefined ? 0 :
                this.roundN(prObj.costiAttuazione);


            if (item.lav_codice.includes("_DD_")) {
                rigaEXcel.Contributo_spettante = this.roundN(Number(item.lav_imp_detraibile_enea))
            } else {
                if (prObj.contributoSpettante <= 0) {
                    rigaEXcel.Contributo_spettante = prObj.contributoSpettante == undefined ? 0 :
                        this.roundN((-1 * Number(prObj.contributoSpettante)));
                } else {
                    rigaEXcel.Contributo_spettante = prObj.contributoSpettante == undefined ? 0 :
                        this.roundN(prObj.contributoSpettante);
                }
            }


            let Importo_lavori_a_carico_del_cliente = 0
            item.lav_codice.includes("_DD_")
                ? Importo_lavori_a_carico_del_cliente = this.roundN(Number(item.lav_importo.toString()))
                : Importo_lavori_a_carico_del_cliente = prObj.ImportoLavoriCaricoCliente == undefined ? 0
                    : this.roundN(prObj.ImportoLavoriCaricoCliente);


            rigaEXcel.Importo_lavori_a_carico_del_cliente = Importo_lavori_a_carico_del_cliente

            rigaEXcel.Importo_non_detraibile =
                item.lav_codice.includes("_DD_")
                    ? this.roundN(Number(item.lav_max_detraibile))
                    : this.roundN(Number(prObj.importoNONDetraibileACaricoCliente));



            if (item.lav_codice.includes("_DD_")) {
                rigaEXcel.Importo_detraibile = this.roundN(Number(item.lav_imp_detraibile_enea))
            }
            else {
                rigaEXcel.Importo_detraibile = this.roundN(Number(item.lav_importo)) -
                    this.roundN(Number(prObj.importoNONDetraibileACaricoCliente))
            }

            rigaEXcel.Totale_dovuto_dal_cliente = item.lav_codice.includes("_DD_")
                ? this.roundN(Number(item.lav_importo.toString())) : this.roundN(prObj.totaleDovutoCliente);
            rigaEXcel.Mq_Installati = this.roundN(Number(item.lav_mq_installati) +
                Number(item.lav_mq_installati_co));
            rigaEXcel.Asseverazione = item.lav_asseverazione

            if (item.lav_asseverazione === 'NO') {
                rigaEXcel.AsseverazioneCosto = 0;
            } else {
                rigaEXcel.AsseverazioneCosto = this.roundN(
                    ((Number(imponibile_serramentista) *
                        Number(item.lat_perc_asseverazione)) /
                        100) +
                    Number(item.lat_costo_asseverazione));
            }

            rigaEXcel.Visto = item.lav_visto

            if (item.lav_visto === 'NO') {
                rigaEXcel.VistoCosto = 0;
            } else {
                rigaEXcel.VistoCosto = this.roundN(
                    ((Number(imponibile_serramentista) * Number(item.lat_perc_visto)) / 100) + Number(item.lat_costo_visto));
            }

            rigaEXcel.Pratica_Enea = item.lav_praticaenea == true ? 'SI' : 'NO';
            rigaEXcel.Costo_Enea = this.roundN(Number(item.lav_costoenea));

            rigaEXcel.Account = item.account;

            this.ExpExcel.push(rigaEXcel);
            rigaEXcel = {};
        });
    }

    roundN(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}


