import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-html-preview",
    templateUrl: "./html-preview.component.html",
    styleUrls: ["./html-preview.component.scss"],
})
export class HtmlPreviewComponent {
    public lavId: number = 0;
    public lavStato: number = 0;
    private baseUrl: string = environment.baseUrl;
    public htmlText: string;
    public downIsDisable: boolean = true;
    public tipo: string = "";

    constructor(private http: HttpClient, private config: DynamicDialogConfig) {
        this.lavId = this.config.data.id;
        this.lavStato = this.config.data.stato;
        this.tipo = this.config.data.tipo || "lavori";
        this.getHtml().subscribe((data) => {
            if (this.isJson(data)) {
                let jp = JSON.parse(data);
                this.htmlText = jp.message;
            } else {
                this.downIsDisable = false;
                this.htmlText = data;
            }
        });
    }

    private isJson(json: string): boolean {
        try {
            JSON.parse(json);
        } catch (e) {
            return false;
        }
        return true;
    }

    getHtml(): Observable<any> {
        if (this.tipo === "preventivo") {
            let url = this.baseUrl + "/preventivi/anteprima/" + this.lavId;
            return this.http.get(url, { responseType: "text" });
        } else {
            let url = this.baseUrl + "/docx/offerta_globale_html/" + this.lavId;
            return this.http.get(url, { responseType: "text" });
        }
    }

    downloaDocumento(): void {
        // this.modalDown = false;
        if (this.tipo === "preventivo") {
            window.location.href =
                this.baseUrl + "/preventivi/pdf/" + this.lavId;
        } else {
            window.location.href =
                this.baseUrl + "/docx/offerta_globale_pdf/" + this.lavId;
        }
    }
}
