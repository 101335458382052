import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ChiavaValore } from "../_interfaces/DataChiavaValore";

@Injectable({
    providedIn: "root",
})
export class ChiavevaloreService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {}

    getAll(): Observable<{ data: ChiavaValore[] }> {
        return this.http
            .get(`${this.baseUrl}/parametri_re`)
            .pipe(map((res: { data: ChiavaValore[]}) => res));
    }

    addKeyValue(keyValue: ChiavaValore): Observable<{res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/parametri_re/add`, keyValue ).pipe(
          map((res: { res: string, message: string }) => res));
      }
    

}
