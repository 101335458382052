import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Messaggi, MessaggiAdmin } from '../_interfaces/messaggi';

@Injectable({
  providedIn: 'root'
})
export class MessaggiPreventiviService {
  private baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getMessaggiByPreventivo(idPreventivo: string): Observable<{ data: Messaggi[] }> {
    return this.http.get(`${this.baseUrl}/messaggi_prev/get_preventivo/${idPreventivo}`).pipe(
      map((res: { data: Messaggi[] }) => res)
    );
  }

  getMessaggiAdminByPrev(idPreventivo: string): Observable<{ data: MessaggiAdmin[] }> {
    return this.http.get(`${this.baseUrl}/messaggi_admin_prev/get_lavoro/${idPreventivo}`).pipe(
      map((res: { data: MessaggiAdmin[] }) => res)
    );
  }

  getNrMessaggiNONLetti(idPreventivo: string): Observable<{ cnt: number }> {
    return this.http.get(`${this.baseUrl}/messaggi_prev/cnt_nl/${idPreventivo}`).pipe(
      map((res: { cnt: number }) => res)
    );
  }

  addMessaggio(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi_prev/add`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  addMessaggioAdminPrev(messaggio: MessaggiAdmin): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi_admin_prev/add`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  updMessaggiLetti(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi_prev/up_preventivo`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  updMessaggioNONLetto(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi_prev/up_messaggio`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }

  cancMessaggio(messaggio: Messaggi): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/messaggi/del_messaggio`, messaggio).pipe(
      map((res: { res: string, message: string }) => res));
  }



}
