import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtenteAttrs } from '../_interfaces/utente-attrs';

@Injectable({
  providedIn: 'root'
})

export class UtentiAttrsService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getUserAttrs(userID:string, tipo: string): Observable<{ data: UtenteAttrs[] }> {
    return  this.http.get(`${this.baseUrl}/users_attrs/${userID}/${tipo}`).pipe(
      map((res: { data: UtenteAttrs[] }) => res)
    );
  }


  addAttrs(userAttrs:UtenteAttrs[]): Observable<{res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/users_attrs/add`, userAttrs ).pipe(
      map((res: { res: string, message: string }) => res));
  }

}
