import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Catastale, ProdottiInstallati, TipoAgevolazione, ZonaClimatica, TitolaritaImmobile, TipoIntervento, CessionarioFiscale, InterventoStato } from '../_interfaces/list-type';
import { PreventiviStato } from '../_interfaces/preventivi';

@Injectable({
  providedIn: 'root'
})
export class ListTypeService {
  private baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getCatastale(): Observable<{ data: Catastale[] }> {
    return this.http.get(`${this.baseUrl}/catastali`).pipe(
      map((res: { data: Catastale[] }) => res)
    );
  }

  getProdottiInstallati(): Observable<{ data: ProdottiInstallati[] }> {
    return this.http.get(`${this.baseUrl}/installati`).pipe(
      map((res: { data: ProdottiInstallati[] }) => res)
    );
  }

  getTipoAgevolazione(): Observable<{ data: TipoAgevolazione[] }> {
    return this.http.get(`${this.baseUrl}/agevolazioni`).pipe(
      map((res: { data: TipoAgevolazione[] }) => res)
    );
  }

  getTipoInterventi(): Observable<{ data: TipoIntervento[] }> {
    return this.http.get(`${this.baseUrl}/interventi`).pipe(
      map((res: { data: TipoIntervento[] }) => res)
    );
  }

  getZonaClimatica(): Observable<{ data: ZonaClimatica[] }> {
    return this.http.get(`${this.baseUrl}/zona_climatica`).pipe(
      map((res: { data: ZonaClimatica[] }) => res)
    );
  }

  getTitolaritaImmobile(): Observable<{ data: TitolaritaImmobile[] }> {
    return this.http.get(`${this.baseUrl}/titolarita`).pipe(
      map((res: { data: TitolaritaImmobile[] }) => res)
    );
  }

  getCessionariFiscali(): Observable<{ data: CessionarioFiscale[] }> {
    return this.http.get(`${this.baseUrl}/cessionario_fiscale`).pipe(
      map((res: { data: CessionarioFiscale[] }) => res)
    );
  }

  getInterventoStati(): Observable<{ data: InterventoStato[] }> {
    return this.http.get(`${this.baseUrl}/interventi_stato`).pipe(
      map((res: { data: InterventoStato[] }) => res)
    );
  }

  getPreventivoStati(): Observable<{ data: PreventiviStato[] }> {
    return this.http.get(`${this.baseUrl}/preventivi_stato`).pipe(
      map((res: { data: PreventiviStato[] }) => res)
    );
  }
}
