import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { Preventivi } from "src/app/_interfaces/preventivi";
import { AuthService } from "src/app/_services/auth.service";
import { PreventiviService } from "src/app/_services/preventivi.service";
import { AppBreadcrumbService } from "src/app/app.breadcrumb.service";
import { environment } from "src/environments/environment";
import { HtmlPreviewComponent } from "../../html-preview/html-preview.component";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { PreventiviStato } from "src/app/_interfaces/preventivi";
import { ListTypeService } from "src/app/_services/list-type.service";

@Component({
    selector: "app-preventivi-list",
    templateUrl: "./preventivi-list.component.html",
    styleUrls: ["./preventivi-list.component.scss"],
})
export class PreventiviListComponent implements OnInit {
    @ViewChild("dt", { static: false }) dt;
    @ViewChild("searchField", { static: false }) searchField;

    logStati: PreventiviStato[];
    environment = environment;
    preventiviLista: Preventivi[];
    ruolo: string;
    modalStato: boolean = false;
    statoOfferta: PreventiviStato[];
    interventoStato: PreventiviStato[];
    frmStato: FormGroup;
    frmCodice: FormGroup;
    preventivoStato: number;


    blockedDocument: boolean = false;
    modalCodice: boolean = false;

    sesso: {} = {
        "first": 0,
        "rows": 10
    }
    sesso2: {} = {
        "first": 0,
        "rows": 100
    }


    constructor(
        private preventiviSrv: PreventiviService,
        private breadcrumbService: AppBreadcrumbService,
        private listTypeSrv: ListTypeService,
        private authSrv: AuthService,
        private msgService: MessageService,
        private dialogService: DialogService
    ) { }

    ngOnInit(): void {
        this.ruolo = sessionStorage.getItem("ruolo");
        this.breadcrumbService.setItems([{ label: "Preventivi" }]);
        this.modalCodice = false;
        this.preventiviLista = [];
        this.refreshListaPreventivi();
        this.refreshInterventoStati();
        this.frmStato = new FormGroup({
            prev_stato: new FormControl(null, Validators.required),
        });
        this.frmCodice = new FormGroup({
            prev_id: new FormControl(0),
            prev_codice: new FormControl(""),
        });
    }

    refreshListaPreventivi(): void {
        this.preventiviLista = [];

        if (this.ruolo != "SE") {
            this.preventiviSrv.getListaPreventivi().subscribe((res) => {
                res.data.forEach((x) => {
                    let preventivo = x;
                    this.preventiviLista.push(preventivo);
                });
                this.sortPreventivi();
                this.dt.reset();
            });
        } else {
            var userId = Number(this.authSrv.getUserId());

            this.preventiviSrv
                .getListaPreventiviByUser(userId)
                .subscribe((res) => {
                    res.data.forEach((x) => {
                        let preventivo = x;
                        this.preventiviLista.push(preventivo);
                    });
                    this.sortPreventivi();
                    this.dt.reset();
                });
        }
    }

    sortPreventivi() {
        this.preventiviLista = this.preventiviLista.sort(function (a, b) {
            if (a.prev_codice.toLowerCase() > b.prev_codice.toLowerCase())
                return -1;

            if (a.prev_codice.toLowerCase() < b.prev_codice.toLowerCase())
                return 1;

            return 0;
        });
    }



    openModalCodice(id, codice) {
        this.modalCodice = true;
        this.frmCodice.controls.prev_codice.setValue(codice)
        this.frmCodice.controls.prev_id.setValue(id)
    }

    OpenModal(idPreventivo: number, idPreventivoStato: number): void {
        this.preventivoStato = idPreventivo;

        this.modalStato = true;
        if (this.ruolo === "SA") {
            this.statoOfferta = this.interventoStato;
        } else if (idPreventivoStato >= 3 && this.ruolo === "SE") {
            // se è ko non si puo cambiare
            this.statoOfferta = [];
            // this.preventiviSrv
            //     .getPreventivoById(idPreventivo)
            //     .subscribe((res) => {
            //         this.frmStato.controls.prev_stato.setValue(res.prev_stato);
            //     });
        } else {
            this.statoOfferta = this.interventoStato.filter(
                (s) => s.prevs_id === 5
            );
            // this.frmStato.controls.prev_stato.setValue(5);
        }

        // else if (this.ruolo == "AD") {
        //     this.statoOfferta = this.interventoStato.filter(
        //         (s) => s.prevs_id >= idPreventivoStato && s.prevs_id != 2
        //     );
        // } else {
        //     this.statoOfferta = this.interventoStato;
        // }
        this.refreshLogStati(idPreventivo);
    }

    submitCodice(id: number, codice: string): void {
        console.log("id, codice", id, codice)
        this.preventiviSrv.upCodiceManuale(id, codice).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'danger',
                summary: `Sato Offerta`,
                detail: res.res === 'ok' ? 'Stato Salvato' : 'Errore in fase di Salvataggio',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.blockedDocument = false;
            this.CloseModal();
            this.refreshListaPreventivi();
        });
    }

    refreshLogStati(idPreventivo: number): void {
        this.preventiviSrv.getLogStati(idPreventivo).subscribe((res) => {
            this.logStati = [...res.data];
        });
    }

    async Submit() {
        this.blockedDocument = true;

        let preventivoDatabase: Preventivi;

        try {
            preventivoDatabase = await this.preventiviSrv
                .getPreventivoById(this.preventivoStato)
                .toPromise();
        } catch (e) { }
        // this.preventiviSrv.getPreventivoById(this.preventivoStato).subscribe((res) => {
        //     preventivoDatabase = res.data[0];
        // });

        preventivoDatabase.prev_stato = this.frmStato.controls.prev_stato.value;
        preventivoDatabase.prev_preventivo = JSON.parse(
            preventivoDatabase.prev_preventivo.toString()
        );
        this.preventiviSrv
            .salvaPreventivo(preventivoDatabase)
            .subscribe((res) => {
                this.msgService.add({
                    key: "tst",
                    severity: res.res === "ok" ? "success" : "danger",
                    summary: `Sato Preventivo`,
                    detail:
                        res.res === "ok"
                            ? "Stato Salvato"
                            : "Errore in fase di Salvataggio",
                            life: 8000 // Tempo in millisecondi (10 secondi)
                });
                this.blockedDocument = false;
                this.CloseModal();
                this.refreshListaPreventivi();
            });
    }

    RemoveFind(): void {
        this.dt.reset();
        this.searchField.value = "";
        sessionStorage.removeItem("statePerv-session");
    }

    BadgeColor(off: Preventivi): string {
        if (off.prev_stato === 1) {
            return "p-button-rounded p-button-info";
        } else if (off.prev_stato === 2) {
            return "p-button-rounded p-button-warning";
        } else if (off.prev_stato === 3) {
            // if ci sono criticità
            if (off.validato_doc1 === "2" || off.validato_doc1 === "3" ||
                off.validato_doc2 === "2" || off.validato_doc2 === "3" ||
                off.validato_doc3 === "2" || off.validato_doc3 === "3" ||
                off.validato_doc4 === "2" || off.validato_doc4 === "3"
            ) {
                return "p-button-rounded orange";
            } else if (
                off.validato_doc1 === "0" &&
                off.validato_doc2 === "0" &&
                off.validato_doc3 === "0"
            ) {
                return "p-button-rounded violet-dark";
            } else if (
                off.validato_doc1 === "1" &&
                off.validato_doc2 === "1" &&
                off.validato_doc3 === "1"
            ) {
                return "p-button-rounded  violet";
            } else {
                return "p-button-rounded violet-dark";
            }
        } else if (off.prev_stato === 4) {
            return "p-button-rounded p-button-success";
        } else if (off.prev_stato === 5) {
            return "p-button-rounded p-button-danger";
        }
    }
    BadgeLabel(off: Preventivi): string {
        if (off.prev_stato === 1) {
            return "Elaborato";
        } else if (off.prev_stato === 2) {
            return "Presentato";
        } else if (off.prev_stato === 3) {
            return "Da Inserire";
        } else if (off.prev_stato === 4) {
            return "Approvato";
        } else if (off.prev_stato === 5) {
            return "KO";
        }
        return "";
    }

    openModalDown(id, stato) {
        // this.docLavId = id;
        const ref = this.dialogService.open(HtmlPreviewComponent, {
            width: "70%",
            contentStyle: { overflow: "auto" },
            baseZIndex: 10000,
            data: { id: id, stato: stato, tipo: "preventivo" },
        });

        ref.onClose.subscribe(() => {
            this.refreshListaPreventivi();
        });
    }

    CloseModal(): void {
        this.frmStato.controls.prev_stato.setValue(null)
        this.modalStato = false;
    }

    refreshInterventoStati(): void {
        this.listTypeSrv.getPreventivoStati().subscribe((res) => {
            this.interventoStato = [...res.data];
        });
    }

    CloseModalCodice(): void {
        this.modalCodice = false;
    }

}
