import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Credito } from "../_interfaces/credito";

@Injectable({
    providedIn: "root",
})
export class CreditoService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {}

    movCrediti(user_credit: Credito): Observable<Credito> {
        return this.http
            .post(`${this.baseUrl}/users_crediti/add`, user_credit)
            .pipe(map((res: Credito) => res));
    }

    delCrediti(uc_id: number): Observable<any> {
        return this.http
            .post(`${this.baseUrl}/users_crediti/del`, { uc_id: uc_id })
            .pipe(map((res: any) => res));
    }

    getCrediti(idSerr: number): Observable<{ data: Credito[] }> {
        return this.http
            .get(`${this.baseUrl}/users_crediti/by_serr/${idSerr}`)
            .pipe(map((res: { data: Credito[] }) => res));
    }

    getKo(idSerr: number): Observable<{ cnt_ko: number }> {
        return this.http
            .get(`${this.baseUrl}/users_crediti/ko_by_serr/${idSerr}`)
            .pipe(map((res: { data: { cnt_ko: number }[] }) => res.data[0]));
    }
}
