import { Component, OnInit, ViewChild } from '@angular/core';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Offerta } from 'src/app/_interfaces/lavori';
import { LavoriService } from 'src/app/_services/lavori.service';
import { MessaggiService } from 'src/app/_services/messaggi.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lavori-pratiche',
    templateUrl: './lavori-pratiche.component.html',
    styleUrls: ['./lavori-pratiche.component.scss']
})



export class LavoriPraticheComponent implements OnInit {

    @ViewChild('dt', { static: false }) dt;
    @ViewChild('searchField', { static: false }) searchField;

    environment = environment;
    praticheLavori: Offerta[];
    ruolo: string;
    baseUrl: string = environment.baseUrl;

    constructor(
        private offerteSrv: LavoriService,
        private breadcrumbService: AppBreadcrumbService,
        private msgSrv: MessaggiService
    ) {
    }

    ngOnInit(): void {
        this.ruolo = sessionStorage.getItem('ruolo');
        this.breadcrumbService.setItems([
            { label: 'Lavori' },
        ]);
        this.praticheLavori = [];
        this.refreshLavoriPratiche();
    }

    // 3 - Approvatas
    refreshLavoriPratiche(): void {
        sessionStorage.removeItem("stateStep-session");
        this.praticheLavori = [];
        if (this.ruolo != 'SE') {
            this.offerteSrv.getInterventoStato(3, this.ruolo).subscribe((res) => {
                res.data.forEach((x) => {
                    let pratiche = x
                    if (x.is_nome == "KO") {
                        x.stato = {
                            value: this.StepStatus(-1, -1),
                            color: this.BadgeColor(-1, -1),
                        };
                    } else {
                        x.stato = {
                            value: this.StepStatus(x.step_numero, x.lat_chiusura_pratica),
                            color: this.BadgeColor(x.step_numero, x.lat_chiusura_pratica)
                        };

                    }
                    x.statoAtt = { value: this.BadgeStep(x.step_numero, x.lat_chiusura_pratica), color: this.BadgeColor(x.step_numero, x.lat_chiusura_pratica) }
                    this.praticheLavori.push(pratiche)
                })
            });
            this.RemoveFind()
        } else {
            this.offerteSrv.getInterventoStatoByUser(3, sessionStorage.getItem("id"), this.ruolo).subscribe((res) => {
                res.data.forEach((x) => {
                    let pratiche = x
                    if (x.is_nome == "KO") {
                        x.stato = {
                            value: this.StepStatus(-1, -1),
                            color: this.BadgeColor(-1, -1),
                        };
                    } else {
                        x.stato = {
                            value: this.StepStatus(x.step_numero, x.lat_chiusura_pratica),
                            color: this.BadgeColor(x.step_numero, x.lat_chiusura_pratica)
                        };

                    }
                    x.statoAtt = { value: this.BadgeStep(x.step_numero, x.lat_chiusura_pratica), color: this.BadgeColor(x.step_numero, x.lat_chiusura_pratica) }
                    this.praticheLavori.push(pratiche)
                })

            });
            this.RemoveFind()
        }
    }

    BadgeStep(numeroStep: number, chiusuraPratica): number {

        const chiusura = Number(chiusuraPratica) === null ? 7 : Number(chiusuraPratica);
        if (numeroStep == null) {
            return 1;
        } else if (numeroStep === Number(chiusura)) {
            return Number(chiusura)
        } else {
            return numeroStep + 1 === 8 ? 7 : numeroStep + 1;
        }
    }


    BadgeColor(numeroStep: number, chiusuraPratica): string {
        if (numeroStep == -1 && chiusuraPratica == -1) {
            return 'danger'
        }
        const chiusura = Number(chiusuraPratica) === null ? 7 : Number(chiusuraPratica);
        if (numeroStep == null) {
            return "info";
        } else if (numeroStep === Number(chiusura)) {
            return "success";
        } else {
            return "info";
        }
    }



    StepStatus(numeroStep: number, chiusuraPratica): string {
        if (numeroStep == -1 && chiusuraPratica == -1) {
            return "KO"
        }
        const chiusura = Number(chiusuraPratica) === null || !chiusuraPratica ? 7 : Number(chiusuraPratica);
        if (numeroStep == null) {
            return "Da inserire step 1";
        } else if (numeroStep >= 1 && numeroStep <= (Number(chiusura) - 1)) {
            return "Validato step " + Number(numeroStep).toString();
        } else if (numeroStep === Number(chiusura)) {
            return "Ultimato";
        }
    }

    BadgeFileCaricati(statoAtt, lav, file, ass) {
        switch (statoAtt) {
            case 1:
                return lav != 'BC' ? `${file}/5` : `${file}/6`;
            case 2:
                return lav != 'SSCO' ? `${file}/10` : `${file}/11`;
            case 3:
                return ass == 'NO' ? `${file}/2` : `${file}/3`;
            case 4:
                return `${file}/4`;
            case 5:
                return `${file}/1`;
            case 6:
                return `${file}/1`;
            case 7:
                return `${file}/1`;
        }
    }


    BadgeColorstato(stato: any): string {
        if (stato === 'Elaborata') {
            return "info";
        } else if (stato === 'Presentata') {
            return "warning";
        } else if (stato === 'Approvata') {
            return "success";
        } else {
            return "danger";
        }
    }

    /*badgeColorFile(off:Offerta): string {
        if (off.sr_critico_serio >= 1) {
            return "danger";
        } else if (off.sr_critico_non_serio >= 1) {
            return "warning";
        } else if (off.sr_validato >= 1) {
            return "success";
        } else if (off.sr_da_caricare == off.sr_caricati) { //boh
            return "p-button-rounded p-button-secondary";  
        } else {
            return "info";
        }
    }*/

    BadgeColorFileCaricati(off: Offerta): string {
        if (off.sr_critico_serio >= 1) {
            return "p-button-rounded p-button-danger";
        } else if (off.sr_critico_non_serio >= 1) {
            return "p-button-rounded p-button-warning";
        } else if (off.sr_validato >= 1) {
            return "p-button-rounded p-button-success";
        } else if (off.sr_da_caricare == off.sr_caricati) {
            return "p-button-rounded p-button-secondary";
        } else {
            return "p-button-rounded p-button-info";
        }
        return
    }


    RemoveFind(): void {
        this.dt.clear();
        this.searchField.value = "";
        sessionStorage.removeItem("stateStep-session");
        this.refreshLavoriPratiche();
    }

    scaricaDocumentoAdmin(lavId: string) {
        const url = `${this.baseUrl}/download_documenti/lavoro/${lavId}`;
        console.log("scaricaDocumentoAdmin ", `${this.baseUrl}/download_documenti/lavoro/${lavId}`)
        window.open(url, '_blank');
    }

    scaricaDocumentoSerr(lavId: string, serrId: string) {
        const url = `${this.baseUrl}/download_documenti/lavoro_serr/${lavId}/${serrId}`;
        console.log("scaricaDocumentoSerr ", `${this.baseUrl}/download_documenti/lavoro_serr/${lavId}/${serrId}`)
        window.open(url, '_blank');
    }

}
