import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DocLavoro, StepLavoro, LavoriStep45, AggregatiGroup, Aggregati, AggregatiPerNome } from '../_interfaces/step-lavori';

@Injectable({
  providedIn: 'root'
})
export class StepLavoriService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  //Invio email Standard
  InvioEmailStandard(idLav: number, idSerr: number): Observable<{ res: string, message: string }> {
    return this.http.get(`${this.baseUrl}/step/invio/email/${idLav}/${idSerr}`).pipe(
      map((res: { res: string, message: string }) => res)
    );
  }
  //Invio email Criticità --> AGG. ID (VEDI POSTMAN)
  InvioEmailCriticità(id: number): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/step/email_criticita`, {lavoro_id: id}).pipe(   
      map((res: { res: string, message: string }) => res)
    );
  }

  //Invio email Caricamenti Documenti Caricati --> AGG. ID (VEDI POSTMAN)
  InvioEmailCaricamenti(lavoroId: number, stepId: number): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/step/email_caricamenti`, { lavoro_id: lavoroId, step: stepId }).pipe(
      map((res: { res: string, message: string }) => res)
    );
  }

    //Invio email DOP --> AGG. ID (VEDI POSTMAN)
    InvioEmailDOP(lavoroId: number): Observable<{ res: string, message: string }> {
      return this.http.post(`${this.baseUrl}/interventi_offerte/email_dop`, { lavoro_id: lavoroId }).pipe(
        map((res: { res: string, message: string }) => res)
      );
    }

     //Invio email DOP --> AGG. ID (VEDI POSTMAN)
     InvioEmailPRED(lavoroId: number): Observable<{ res: string, message: string }> {
      return this.http.post(`${this.baseUrl}/interventi_offerte/email_doc_pred`, { lavoro_id: lavoroId }).pipe(
        map((res: { res: string, message: string }) => res)
      );
    }

  //lista Step per OFFERTA (step Validati)
  getStep(idOfferta: string): Observable<{ data: StepLavoro[] }> {
    return this.http.get(`${this.baseUrl}/step/step_lavoro/${idOfferta}`).pipe(
      map((res: { data: StepLavoro[] }) => res)
    );
  }
  //lista doc lavori della OFFERTA selezionata per ID
  getDocumentiLavoro(idOfferta: number): Observable<{ data: DocLavoro[] }> {
    return this.http.get(`${this.baseUrl}/step/docs_lavoro/${idOfferta}`).pipe(
      map((res: { data: DocLavoro[] }) => res)
    );
  }
  //lista doc lavori della OFFERTA selezionata per ID e singolo STEP
  getDocumentiStep(idOfferta: string, idStep: number): Observable<{ data: DocLavoro[] }> {
    return this.http.get(`${this.baseUrl}/step/docs_lavoro_step/${idOfferta}/${idStep}`).pipe(
      map((res: { data: DocLavoro[] }) => res)
    );
  }
  //lista doc lavori della OFFERTA selezionata per ID e singolo STEP e numero DOCUMENTO
  getDocumentiStepRiga(idOfferta: number, idStep: number, numDoc: number): Observable<{ data: DocLavoro[] }> {
    return this.http.get(`${this.baseUrl}/step/docs_lavoro_step_num/${idOfferta}/${idStep}/${numDoc}`).pipe(
      map((res: { data: DocLavoro[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE per SERRAMENTISTA dove la pratica si trova nello step 5 non NON ancora Validato
  getDocumentiStep45(idSerramentista: string): Observable<{ data: LavoriStep45[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/user/step45/${idSerramentista}`).pipe(
      map((res: { data: LavoriStep45[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE AGGREGATI per step (5,6,7)
  getStepAggredati(step: string): Observable<{ data: AggregatiPerNome[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/group/nome/${step}`).pipe(
      map((res: { data: AggregatiPerNome[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE AGGREGATI per step (5,6,7) e SERRAMENTISTA
  getStepAggredatiUtenti(step: string, idUser: string): Observable<{ data: AggregatiPerNome[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/group/nome/user/${step}/${idUser}`).pipe(
      map((res: { data: AggregatiPerNome[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE AGGREGATI per NOME_AGGREGANTE
  getStepAggregatiNome(nome: string, serramentista: string): Observable<{ data: Aggregati[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/aggregati_nome_serr/${nome}/${serramentista}`).pipe(
      map((res: { data: Aggregati[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE AGGREGATI per NOME_AGGREGANTE e check della validazione numero di STEP (5,6,7)
  getAggregatiNomeCheck(nome: string, step: number, serr: number): Observable<{ data: Aggregati[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/aggregati_nome_check/${nome}/${step}/${serr}`).pipe(
      map((res: { data: Aggregati[] }) => res)
    );
  }
  //lista dei LAVORI/OFFERTE AGGREGATI per NOME_AGGREGANTE e check della validazione numero di STEP (5,6,7) ESCLUSI
  getAggregatiNomeCheckEsclusi(nome: string, step: number, serr: number): Observable<{ data: Aggregati[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/non_validati/${nome}/${step}/${serr}`).pipe(
      map((res: { data: Aggregati[] }) => res)
    );
  }

  //lista AGGREGATI per NOME_AGGREGANTE e la_validato_X (5,6,7) AMMINISTRATORE
  getGroupAggregatiNomeCheckNrValidazione(step: number): Observable<{ data: Aggregati[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/group_check/${step}`).pipe(
      map((res: { data: Aggregati[] }) => res)
    );
  }

  //lista AGGREGATI per NOME_AGGREGANTE e la_validato_X (5,6,7) SERRAMENTISTA
  getGroupAggregatiNomeCheckNrValidazioneSERR(step: number, serr: string): Observable<{ data: Aggregati[] }> {
    return this.http.get(`${this.baseUrl}/interventi_offerte/group_check_user/${serr}/${step}`).pipe(
      map((res: { data: Aggregati[] }) => res)
    );
  }

  //Aggiunta/Modifica del documento Lavoro
  addDettaglioUpload(docLavoro: DocLavoro): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/step/add_documento`, docLavoro).pipe(
      map((res: { res: string, message: string }) => res));
  }
  //Aggiunta/Modifica dello Step
  addStep(step: StepLavoro): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/step/add`, step).pipe(
      map((res: { res: string, message: string }) => res));
  }
  //Aggiunta/Modifica dello Step
  addAggregati(stepAgg: AggregatiGroup[]): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/interventi_offerte/add_aggregati`, stepAgg).pipe(
      map((res: { res: string, message: string }) => res));
  }
  //Modifica Aggregati per nome gestore step numero
  updGestoreAggregatiStep5(nomeAggregante: string, numstep: string, num: string, stato: number, serramentistaID: string): Observable<{ res: string, message: string }> {
    let doc: { nome: string, gestore: number, step: string, numero: string, serr: string } = { nome: nomeAggregante, gestore: stato, step: numstep, numero: num, serr: serramentistaID }
    return this.http.post(`${this.baseUrl}/step/up_gestore_aggr`, doc).pipe(
      map((res: { res: string, message: string }) => res));
  }
  //Modifica singola riga di Aggregati per selezionare/deselezionare lavori per Step 6 e 7
  updAggregati_LavoriSelezionatiPerStepSuccessivo(idAgg: number, StatoNum: number, stepAttuale: number): Observable<{ res: string, message: string }> {
    let sel: { id: number, validato: number, step: number } = { id: idAgg, validato: StatoNum, step: stepAttuale };
    return this.http.post(`${this.baseUrl}/interventi_offerte/up_validato_id`, sel).pipe(
      map((res: { res: string, message: string }) => res));
  }

  //Modifica Aggregati per nome gestore step numero
  delAggregatiStep5(id: number): Observable<{ res: string, message: string }> {
    let del: { lav_id: number } = { lav_id: id };
    return this.http.post(`${this.baseUrl}/interventi_offerte/del_aggregato`, del).pipe(
      map((res: { res: string, message: string }) => res));
  }

  //Modifica Aggregati per nome gestore step numero
  delCessioneCredito(id: string, lavoro: string, numerazione: string): Observable<{ res: string, message: string }> {
    let del: { la_id: number, la_lavoro: number, la_numerazione: number } = { la_id: Number(id), la_lavoro: Number(lavoro), la_numerazione: Number(numerazione) };
    return this.http.post(`${this.baseUrl}/interventi_offerte/del_aggregato_num`, del).pipe(
      map((res: { res: string, message: string }) => res));
  }

  //Delete Step Admin
  delStepAdmin(id: string): Observable<{ res: string, message: string }> {
    const del: { docl_id: string } = { docl_id: id };
    return this.http.post(`${this.baseUrl}/step/del_documento`, del).pipe(
      map((res: { res: string, message: string }) => res));
  }

}
