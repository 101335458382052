import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { GeneraForm } from "src/app/_helpers/genera-form";
import { Utility } from "src/app/_helpers/utility";
import { Abitazioni } from "src/app/_interfaces/abitazioni";
import { Cliente } from "src/app/_interfaces/clienti";
import { Lavori } from "src/app/_interfaces/lavori";
import { TipoIntervento } from "src/app/_interfaces/list-type";
import { Preventivi } from "src/app/_interfaces/preventivi";
import { AuthService } from "src/app/_services/auth.service";
import { LavoriService } from "src/app/_services/lavori.service";
import { PreventiviService } from "src/app/_services/preventivi.service";
import { AppBreadcrumbService } from "src/app/app.breadcrumb.service";

@Component({
    selector: "app-preventivo-page",
    templateUrl: "./preventivo-page.component.html",
})
export class PreventivoPageComponent implements OnInit {
    id: string;
    preventivoForm: FormGroup;
    cliente: Cliente;
    abitazione: Abitazioni;
    tipologia: TipoIntervento;
    tipologiaDescrizione: string;
    preventivo: Preventivi;
    loading: boolean = true;
    ruoloUtente: string = "SE";
    from = "";
    preventivoCalcolato: boolean;
    blockedDocument: boolean = false;

    constructor(
        private preventiviSrv: PreventiviService,
        private authSrv: AuthService,
        private msgService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbService: AppBreadcrumbService,
        private lavoriSrv: LavoriService
    ) { }

    async ngOnInit(): Promise<void> {
        this.breadcrumbService.setItems([{ label: `Preventivo` }]);
        this.id = this.route.snapshot.paramMap.get("id");
        this.ruoloUtente = sessionStorage.getItem("ruolo");
        this.from = this.route.snapshot.queryParamMap.get("from");
        if (this.id) {
            // edit mode
            // scarico il preventivo
            let prevRes = await this.preventiviSrv
                .getPreventivoById(Number.parseInt(this.id))
                .toPromise();
            if (this.from === "offerta") {
                // vengo dall'offerta. devo quindi scaricare l'offerta invece che il preventivo
                const resLav = await this.lavoriSrv
                    .getInterventoID(prevRes.prev_lav_id.toString())
                    .toPromise();
                prevRes = Object.keys(resLav.data).reduce((acc, key) => {
                    if(key.startsWith("lav_lavoriAttr")){
                        acc[key.replace("lav_lavoriAttr", "prev_preventiviAttr")] = resLav.data[key];
                    }
                    else if (key.startsWith("lav_")) {
                        acc[key.replace("lav_", "prev_")] = resLav.data[key];
                    } else {
                        acc[key] = resLav.data[key];
                    }
                    return acc;
                }, {}) as Preventivi;
            }
            this.cliente = Object.keys(prevRes).reduce((acc, key) => {
                if (key.startsWith("cl_")) {
                    acc[key] = prevRes[key];
                }
                return acc;
            }, {}) as Cliente;
            this.abitazione = Object.keys(prevRes).reduce((acc, key) => {
                if (key.startsWith("ab_")) {
                    acc[key] = prevRes[key];
                }
                return acc;
            }, {}) as Abitazioni;
            this.tipologia = Object.keys(prevRes).reduce((acc, key) => {
                if (key.startsWith("tin_")) {
                    acc[key] = prevRes[key];
                }
                return acc;
            }, {}) as TipoIntervento;

            this.updateFormPreventivo(prevRes);
            this.preventivo = prevRes;
            this.loading = false;
        } else {
            this.creaFormPreventivo();
            this.preventivo = {} as Preventivi;
            this.loading = false;
        }
    }

    creaFormPreventivo() {
        this.preventivoForm = GeneraForm.createFormPreventivo();
        Utility.updateFormValidity(this.preventivoForm);
    }
    updateFormPreventivo(preventivo: Preventivi) {
        this.preventivoForm = GeneraForm.createFormPreventivo(preventivo);
        Utility.updateFormValidity(this.preventivoForm);
    }
    inviaForm() {
        this.blockedDocument = true;
        sessionStorage.removeItem("stateStep-session");

        var tipologiaSelectForm = this.preventivoForm.controls["tipologiaSelectForm"] as FormGroup;
        var preventivoFormSelect = this.preventivoForm.controls["preventivoForm"] as FormGroup;
        var calcoloPreventivoForm = preventivoFormSelect.controls["prev_preventivo"] as FormGroup;
        var preventivoPaf = preventivoFormSelect.controls["prev_preventiviAttr"] as FormGroup;

        //creazione del json da passare per il salvataggio
        this.preventivo.prev_id = this.id ? Number.parseInt(this.id) : 0;


        if (!this.id) {
            // se il preventivo è nuovo
            this.preventivo.prev_lav_id = 0;
            this.preventivo.lat_id = 0;
            this.preventivo.lat_prev_id = 0;
            this.preventivo.prev_codice = "";
            this.preventivo.prev_tipo = "preventivo";
            this.preventivo.prev_stato = 1; //inserimento nuovo preventivo
        }


        if (this.preventivo.lat_id == null || this.preventivo.lat_id == undefined) {
            this.preventivo.lat_id = 0;
        }

        this.preventivo.prev_user = this.preventivoForm.controls["prev_user"].value;
        this.preventivo.prev_cliente = this.preventivoForm.controls["anagraficaForm"].get("prev_cliente").value;
        this.preventivo.prev_abitazione = this.preventivoForm.controls["abitazioniForm"].get("prev_abitazione").value;
        this.preventivo.prev_tipologia_agevolazione = tipologiaSelectForm.controls.prev_tipologia_agevolazione.value;

        let prev_pag_perc_1 = preventivoFormSelect.controls.prev_pag_perc_1.value == null ? 0 : preventivoFormSelect.controls.prev_pag_perc_1.value;
        let prev_pag_perc_2 = preventivoFormSelect.controls.prev_pag_perc_2.value == null ? 0 : preventivoFormSelect.controls.prev_pag_perc_2.value;
        let prev_pag_perc_3 = preventivoFormSelect.controls.prev_pag_perc_3.value == null ? 0 : preventivoFormSelect.controls.prev_pag_perc_3.value;

        if (((Number(prev_pag_perc_1) + Number(prev_pag_perc_2) + Number(prev_pag_perc_3)) != 100) ||
            preventivoFormSelect.controls.prev_pag_desc_1.value == null || preventivoFormSelect.controls.prev_pag_desc_1.value == undefined
            || preventivoFormSelect.controls.prev_pag_desc_1.value == "") {
            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: `Pagamento`,
                detail: "Modalità pagamento NON 100% oppure Descrizione Pagamento NON inserita",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.blockedDocument = false
        } else {
            if (tipologiaSelectForm.controls.prev_tipologia.value == "BA" ||
                tipologiaSelectForm.controls.prev_tipologia.value == "EB" ||
                tipologiaSelectForm.controls.prev_tipologia.value == "BC_SI") {
                if (tipologiaSelectForm.controls.prev_tipologia_intervento.value == 1) {
                    this.preventivo.prev_tipologia = "SI";
                } else {
                    this.preventivo.prev_tipologia = "SSCO";
                }
            } else if (tipologiaSelectForm.controls.prev_tipologia.value == "BC_AI") {
                this.preventivo.prev_tipologia = "AI";
            } else if (tipologiaSelectForm.controls.prev_tipologia.value == "BA_AI") {
                this.preventivo.prev_tipologia = "AI";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia.value == "BC") {
                this.preventivo.prev_tipologia = "BC";
            }
            this.preventivo.prev_tipologia_intervento = tipologiaSelectForm.controls.prev_tipologia_intervento.value;

            this.preventivo.prev_zona_climatica = tipologiaSelectForm.controls.prev_zona_climatica.value;

            if (this.preventivo.prev_zona_climatica == "NULL")
                this.preventivo.prev_zona_climatica = "";

            this.preventivo.prev_prodotto_installato = tipologiaSelectForm.controls.prev_prodotto_installato.value;

            this.preventivo.prev_mq_installati = tipologiaSelectForm.controls.prev_mq_installati.value;

            this.preventivo.prev_transmittanza = tipologiaSelectForm.controls.prev_transmittanza.value;
            this.preventivo.prev_iva_offerta = preventivoFormSelect.controls.prev_iva_offerta.value;
            this.preventivo.prev_iva_prestazioni = preventivoFormSelect.controls.prev_iva_prestazioni.value;

            this.preventivo.prev_pag_desc_1 = preventivoFormSelect.controls.prev_pag_desc_1.value;
            this.preventivo.prev_pag_perc_1 = preventivoFormSelect.controls.prev_pag_perc_1.value;
            this.preventivo.prev_pag_desc_2 = preventivoFormSelect.controls.prev_pag_desc_2.value;
            this.preventivo.prev_pag_perc_2 = preventivoFormSelect.controls.prev_pag_perc_2.value;
            this.preventivo.prev_pag_desc_3 = preventivoFormSelect.controls.prev_pag_desc_3.value;
            this.preventivo.prev_pag_perc_3 = preventivoFormSelect.controls.prev_pag_perc_3.value;
            this.preventivo.prev_consegna = preventivoFormSelect.controls.prev_consegna.value;
            this.preventivo.prev_cessionario = preventivoFormSelect.controls.prev_cessionario.value;

            this.preventivo.prev_vendita_infissi = preventivoFormSelect.controls.prev_vendita_infissi.value;
            this.preventivo.prev_beni_significativi = preventivoFormSelect.controls.prev_beni_significativi.value;
            this.preventivo.prev_altri_beni = preventivoFormSelect.controls.prev_altri_beni.value;
            this.preventivo.prev_posa_opera = preventivoFormSelect.controls.prev_posa_opera.value;
            this.preventivo.prev_altre_opere = preventivoFormSelect.controls.prev_altre_opere.value;
            this.preventivo.prev_prestazioni_prof = preventivoFormSelect.controls.prev_prestazioni_prof.value;

            this.preventivo.prev_chiusura = tipologiaSelectForm.controls.prev_chiusura.value;

            this.preventivo.prev_note = preventivoFormSelect.controls.prev_note.value;
            this.preventivo.prev_importo = this.preventivoForm.controls.prev_importo.value;

            this.preventivo.prev_asseverazione = preventivoFormSelect.controls.prev_asseverazione.value;

            this.preventivo.prev_praticaenea = preventivoFormSelect.controls.prev_praticaenea.value
            this.preventivo.prev_costoenea = preventivoFormSelect.controls.prev_costoenea.value


            this.preventivo.prev_visto = preventivoFormSelect.controls.prev_visto.value;

            if (tipologiaSelectForm.controls.prev_tipologia.value == "BC") {
                this.preventivo.prev_tipo_detrazione = "BC";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia.value == "BA") {
                this.preventivo.prev_tipo_detrazione = "BA";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia.value == "BC_SI") {
                this.preventivo.prev_tipo_detrazione = "BC_SI";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia.value == "BC_AI") {
                this.preventivo.prev_tipo_detrazione = "BC_AI";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia.value == "BA_AI") {
                this.preventivo.prev_tipo_detrazione = "BA_AI";
            }
            else if (tipologiaSelectForm.controls.prev_tipologia_intervento.value == 1) {
                this.preventivo.prev_tipo_detrazione = "EB";
            } else {
                this.preventivo.prev_tipo_detrazione = "EB";
            }

            this.preventivo.prev_imp_asseverazione = preventivoFormSelect.controls.prev_imp_asseverazione.value;

            this.preventivo.prev_tipo_unita = tipologiaSelectForm.controls.prev_tipo_unita.value;

            if (this.preventivo.prev_tipo_unita == "NULL")
                this.preventivo.prev_tipo_unita = "";

            this.preventivo.prev_descrizione = tipologiaSelectForm.controls.prev_descrizione.value;
            this.preventivo.prev_tipo_bc = tipologiaSelectForm.controls.prev_tipo_bc.value;
            this.preventivo.prev_mq_installati_co = tipologiaSelectForm.controls.prev_mq_installati_co.value;
            this.preventivo.prev_iva_attualizzazione = preventivoFormSelect.controls.prev_iva_attualizzazione.value;
            this.preventivo.prev_imp_max_ass = preventivoFormSelect.controls.prev_imp_asseverazione.value;
            this.preventivo.prev_max_detraibile = preventivoFormSelect.controls.prev_max_detraibile.value;

            //controllare form
            this.preventivo.prev_multi_detrazione = preventivoFormSelect.controls.prev_multi_detrazione.value;
            this.preventivo.prev_finanz = preventivoFormSelect.controls.prev_finanz.value;
            this.preventivo.prev_imp_detraibile_enea = preventivoFormSelect.controls.prev_imp_detraibile_enea.value;
            this.preventivo.prev_recupero_fiscale_anno = preventivoFormSelect.controls.prev_recupero_fiscale_anno.value;
            this.preventivo.prev_note_orc = preventivoFormSelect.controls.prev_note_orc.value;
            this.preventivo.prev_note_admin = preventivoFormSelect.controls.prev_note_admin.value;
            this.preventivo.prev_note_serr = preventivoFormSelect.controls.prev_note_serr.value;
            this.preventivo.lat_tipo = preventivoFormSelect.controls.lat_tipo.value;
            this.preventivo.lat_oneri = preventivoFormSelect.controls.lat_oneri.value;
            this.preventivo.lat_detrazione = preventivoFormSelect.controls.lat_detrazione.value;
            this.preventivo.lat_cessionario = preventivoFormSelect.controls.lat_cessionario.value;
            this.preventivo.lat_chiusura_pratica = preventivoFormSelect.controls.lat_chiusura_pratica.value;
            this.preventivo.lat_range_min = preventivoFormSelect.controls.lat_range_min.value;
            this.preventivo.lat_range_max = preventivoFormSelect.controls.lat_range_max.value;
            this.preventivo.lat_costo_fisso = preventivoFormSelect.controls.lat_costo_fisso.value;
            this.preventivo.lat_imp = preventivoFormSelect.controls.lat_imp.value;
            this.preventivo.lat_costo_min = preventivoFormSelect.controls.lat_costo_min.value;
            this.preventivo.lat_costo_max = preventivoFormSelect.controls.lat_costo_max.value;
            this.preventivo.lat_oneri_att = preventivoFormSelect.controls.lat_oneri_att.value;
            this.preventivo.lat_costo_asseverazione = preventivoFormSelect.controls.lat_costo_asseverazione.value;
            this.preventivo.lat_perc_asseverazione = preventivoFormSelect.controls.lat_perc_asseverazione.value;
            this.preventivo.lat_costo_visto = preventivoFormSelect.controls.lat_costo_visto.value;
            this.preventivo.lat_perc_visto = preventivoFormSelect.controls.lat_perc_visto.value;


            this.preventivo = {
                ...this.preventivo,
                prev_preventiviAttr: {
                    paf_id: preventivoPaf.controls.paf_id.value.toString(),
                    paf_prev_id: preventivoPaf.controls.paf_prev_id.value.toString(),

                    paf_imp_fornitore: preventivoPaf.controls.paf_imp_fornitore.value.toString(),
                    paf_imp_fornitura: preventivoPaf.controls.paf_imp_fornitura.value.toString(),
                    paf_costo_pratica: preventivoPaf.controls.paf_costo_pratica.value.toString(),
                    paf_nuovo_imponibile: preventivoPaf.controls.paf_nuovo_imponibile.value.toString(),
                    paf_iva: preventivoPaf.controls.paf_iva.value.toString(),
                    paf_tot_fattura: preventivoPaf.controls.paf_tot_fattura.value.toString(),
                    paf_imp_detraibile: preventivoPaf.controls.paf_imp_detraibile.value.toString(),
                    paf_imp_non_detraibile: preventivoPaf.controls.paf_imp_non_detraibile.value.toString(),

                    paf_fin_Tipo: preventivoPaf.controls.paf_fin_Tipo.value.toString(),
                    paf_fin_numero_rate: preventivoPaf.controls.paf_fin_numero_rate.value.toString(),
                    paf_fin_importo_rate: preventivoPaf.controls.paf_fin_importo_rate.value.toString(),
                    paf_fin_interessi: preventivoPaf.controls.paf_fin_interessi.value.toString(),
                    paf_fin_totale: preventivoPaf.controls.paf_fin_totale.value.toString(),
                    paf_fin_saldo: preventivoPaf.controls.paf_fin_saldo.value.toString(),

                    paf_re_tipo: preventivoPaf.controls.paf_re_tipo.value.toString(),
                    paf_re_costomedio_elettrica: preventivoPaf.controls.paf_re_costomedio_elettrica.value.toString(),
                    paf_re_costomedio_gas: preventivoPaf.controls.paf_re_costomedio_gas.value.toString(),
                    paf_re_risparmio_elettrica: preventivoPaf.controls.paf_re_risparmio_elettrica.value.toString(),
                    paf_re_risparmio_gas: preventivoPaf.controls.paf_re_risparmio_gas.value.toString(),
                    paf_re_risparmio_annuo: preventivoPaf.controls.paf_re_risparmio_annuo.value.toString(),
                    paf_re_risparmio_tot10anni: preventivoPaf.controls.paf_re_risparmio_tot10anni.value.toString(),
                    paf_re_risparmio_tot30anni: preventivoPaf.controls.paf_re_risparmio_tot30anni.value.toString(),

                    paf_ba_co2_non_emessa: preventivoPaf.controls.paf_ba_co2_non_emessa.value.toString(),
                    paf_ba_consumo_auto: preventivoPaf.controls.paf_ba_consumo_auto.value.toString(),
                    paf_ba_nuovi_alberi: preventivoPaf.controls.paf_ba_nuovi_alberi.value.toString(),
                    paf_tot_costo_10anni: preventivoPaf.controls.paf_tot_costo_10anni.value.toString(),
                    paf_tot_costo_30anni: preventivoPaf.controls.paf_tot_costo_30anni.value.toString(),
                }
            };

            this.preventivo = {
                ...this.preventivo,
                prev_preventivo: {
                    calcolo_massimali: {
                        imponibile: calcoloPreventivoForm.controls.imponibileFornitura.value,
                        massimale_mq: calcoloPreventivoForm.controls.massimaleMQdaApplicare.value,
                        massimale_mq_con_co: calcoloPreventivoForm.controls.massimaleMQdaApplicare_CON_CO.value,
                        costo_mq: calcoloPreventivoForm.controls.costoMQImponibile.value,
                        imp_max_detaibile: calcoloPreventivoForm.controls.imponibileMassimoDetraibile.value,
                        imp_max_detaibile_con_co: calcoloPreventivoForm.controls.imponibileMassimoDetraibile_CON_CO.value,
                        imp_max_detaibile_totale: calcoloPreventivoForm.controls.imponibileMassimoDetraibile_Totale.value,
                        imp_non_detraibile: calcoloPreventivoForm.controls.importoNonDetraibile.value,
                    },
                    preventivatore: {
                        mano_opera: calcoloPreventivoForm.controls.manoOperaImponibile.value,
                        imponibile_serr: calcoloPreventivoForm.controls.imponibileSerramentista.value,
                        spese_tecniche: calcoloPreventivoForm.controls.speseTecniche.value,
                        nuovo_imponibile: calcoloPreventivoForm.controls.nuovoImponibile.value,
                        tot_fattura_con_iva: calcoloPreventivoForm.controls.totaleFatturaIva.value,
                        contributo: calcoloPreventivoForm.controls.contributoSpettante.value,
                        imp_prev_cliente: 0,
                        costi_attuazione: calcoloPreventivoForm.controls.costiAttuazione.value,
                        tot_dovuto_cliente: calcoloPreventivoForm.controls.totaleDovutoCliente.value,
                    },
                    fattura: {
                        posa_opera: {
                            imponibile: calcoloPreventivoForm.controls.posaOperaImponibile.value,
                            iva_10: calcoloPreventivoForm.controls.posaOpera10.value,
                            iva_22: calcoloPreventivoForm.controls.posaOpera22.value,
                            tot: calcoloPreventivoForm.controls.posaOperaTot.value,
                        },
                        altri_beni_accessori: {
                            imponibile: calcoloPreventivoForm.controls.altibeniaccImponibile.value,
                            iva_10: calcoloPreventivoForm.controls.altibeniacc10.value,
                            iva_22: calcoloPreventivoForm.controls.altibeniacc22.value,
                            tot: calcoloPreventivoForm.controls.altibeniaccTot.value,
                        },
                        valore_vendita_infissi_10: {
                            imponibile: calcoloPreventivoForm.controls.valore10Imponibile.value,
                            iva_10: calcoloPreventivoForm.controls.valore1010.value,
                            iva_22: calcoloPreventivoForm.controls.valore1022.value,
                            tot: calcoloPreventivoForm.controls.valore10Tot.value,
                        },
                        valore_vendita_infissi_22: {
                            imponibile: calcoloPreventivoForm.controls.valore22Imponibile.value,
                            iva_10: calcoloPreventivoForm.controls.valore2210.value,
                            iva_22: calcoloPreventivoForm.controls.valore2222.value,
                            tot: calcoloPreventivoForm.controls.valore22Tot.value,
                        },
                        altre_opere: {
                            imponibile: calcoloPreventivoForm.controls.altreOpereImponibile.value,
                            iva_10: calcoloPreventivoForm.controls.altreOpere10.value,
                            iva_22: calcoloPreventivoForm.controls.altreOpere22.value,
                            tot: calcoloPreventivoForm.controls.altreOpereTot.value,
                        },
                        prestazioni_professionali: {
                            imponibile: calcoloPreventivoForm.controls.prestProfImponibile.value,
                            iva_10: calcoloPreventivoForm.controls.prestProf10.value,
                            iva_22: calcoloPreventivoForm.controls.prestProf22.value,
                            tot: calcoloPreventivoForm.controls.prestProfTot.value,
                        },
                        totale: {
                            imponibile: calcoloPreventivoForm.controls.ftImponibile.value,
                            iva_10: calcoloPreventivoForm.controls.ft10.value,
                            iva_22: calcoloPreventivoForm.controls.ft22.value,
                            tot: calcoloPreventivoForm.controls.ftTot.value,
                        },
                    },
                },
            };
            this.preventivo.prev_importo = calcoloPreventivoForm.controls.ftTot.value;
            this.preventivo.prev_max_detraibile = preventivoFormSelect.controls.prev_max_detraibile.value;
            this.preventivo.prev_imp_detraibile_enea = preventivoFormSelect.controls.prev_imp_detraibile_enea.value;
            this.preventivo.prev_recupero_fiscale_anno = preventivoFormSelect.controls.prev_recupero_fiscale_anno.value;

            let controls = tipologiaSelectForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    tipologiaSelectForm.removeControl(name);
                    //invalid.push(name);
                }
            }

            controls = preventivoFormSelect.controls;

            for (const name in controls) {
                if (controls[name].invalid) {
                    preventivoFormSelect.removeControl(name);
                    //invalid.push(name);
                }
            }

            if (this.preventivoForm.valid) {
                if (this.from === "offerta") {
                    const offerta = Object.keys(this.preventivo).reduce(
                        (acc, key) => {
                            if(key.startsWith("prev_preventiviAttr")){
                                acc[key.replace("prev_preventiviAttr", "lav_lavoriAttr")] = this.preventivo[key];
                            }
                            else if (key.startsWith("prev_")) {
                                acc[key.replace("prev_", "lav_")] =
                                    this.preventivo[key];
                            } else {
                                acc[key] = this.preventivo[key];
                            }
                            return acc;
                        },
                        {}
                    ) as Lavori;
                    offerta.lav_id = this.preventivo.lat_lavoro;
                    this.lavoriSrv.addIntervento(offerta).subscribe((res) => {
                        this.msgService.add({
                            key: "tst",
                            severity: res.res === "ok" ? "success" : "error",
                            summary: `Offerta`,
                            detail:
                                res.res === "ok"
                                    ? "Offerta Salvata"
                                    : "Errore in fase di Salvataggio contattare l'amministratore del programma",
                                    life: 8000 // Tempo in millisecondi (10 secondi)
                        });
                        if (res.res === "ok") {
                            this.router.navigate(["offerte"]);
                        } else {
                            this.blockedDocument = true
                        }
                    });
                } else {
                    this.preventiviSrv
                        .salvaPreventivo(this.preventivo)
                        .subscribe((res) => {
                            this.msgService.add({
                                key: "tst",
                                severity: res.res === "ok" ? "success" : "error",
                                summary: `Preventivo`,
                                detail:
                                    res.res === "ok"
                                        ? "Preventivo Salvato"
                                        : "Errore in fase di Salvataggio contattare l'amministratore del programma",
                                        life: 8000 // Tempo in millisecondi (10 secondi)
                            });
                            if (res.res === "ok") {
                                this.router.navigate(["preventivi"]);
                            }
                            else {
                                this.blockedDocument = true
                            }
                        });

                }
            }
        }

    }
}
