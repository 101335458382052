import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Nazioni, Regioni, Citta } from 'src/app/_interfaces/nazioni';
import { NazioniService } from 'src/app/_services/nazioni.service';
import { AutoComplete } from 'primeng/autocomplete';
import { Utility } from 'src/app/_helpers/utility';
import { SerramentistaService } from 'src/app/_services/serramentista.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtcDatePipe } from 'src/app/_pipes/utc-date.pipe';
import { Serramentista } from 'src/app/_interfaces/serramentista';

@Component({
    selector: 'app-cessionario-finale.component',
    templateUrl: './cessionario-finale.component.html',
    styleUrls: ['./cessionario-finale.component.scss'],
})

export class CessionarioFinaleComponent implements OnInit {
    id: string;
    frmSerramentista: FormGroup;
    nazioni: Nazioni[] = [];
    regioni: Regioni[] = [];
    citta: Citta[] = [];
    nazioniSuggestion: any[] = [];
    regioniSuggestion: any[] = [];
    cittaSuggestion: any[] = [];

    serramentista: Serramentista;
    environment = environment;

    baseUrl: string = environment.baseUrl;

    @ViewChild('serr_regione', { static: false })
    serr_regione: AutoComplete;
    @ViewChild('serr_citta', { static: false })
    serr_citta: AutoComplete;

    GeneraSerramentista(): void {
        this.frmSerramentista = new FormGroup({
            serr_id: new FormControl(0),
            serr_user: new FormControl(0),
            serr_ragsoc: new FormControl('', Validators.required),
            serr_tipo: new FormControl('', Validators.required),
            serr_piva: new FormControl('', Validators.required),
            serr_cf: new FormControl('', Validators.required),
            serr_registro_imprese: new FormControl('', Validators.required),
            serr_rea: new FormControl('', Validators.required),
            serr_nazione: new FormControl('', Validators.required),
            serr_regione: new FormControl('', Validators.required),
            serr_provincia: new FormControl('', Validators.required),
            serr_citta: new FormControl('', Validators.required),
            serr_cap: new FormControl('', Validators.required),
            serr_indirizzo: new FormControl('', Validators.required),
            serr_civico: new FormControl('', Validators.required),
            serr_telefono: new FormControl('', Validators.required),
            serr_email: new FormControl('', [Validators.email, Validators.required]),
            serr_pec: new FormControl('', [Validators.email, Validators.required]),
            serr_sdi: new FormControl('', [Validators.required]),
            serr_cancellato: new FormControl(0),
            serr_tipologia: new FormControl('cess'),
            legale: new FormGroup({
                srl_nome: new FormControl(''),
                srl_cognome: new FormControl(''),
                srl_data_nascita: new FormControl(''),
                srl_luogo: new FormControl(''),
                srl_provincia: new FormControl(''),
                srl_cf: new FormControl(''),
                srl_email: new FormControl('', Validators.email),
                srl_telefono: new FormControl(''),
                srl_tipo: new FormControl('cess'),
            }),
            coordinate: new FormGroup({
                cb_banca: new FormControl(''),
                cb_abi: new FormControl(''),
                cb_cab: new FormControl(''),
                cb_numero_cc: new FormControl(''),
                cb_iban: new FormControl(''),
                cb_tipo: new FormControl('cess'),
            }),
            referente: new FormGroup({
                ra_id: new FormControl(0),
                ra_nome: new FormControl('', Validators.required),
                ra_cognome: new FormControl('', Validators.required),
                ra_email: new FormControl('', Validators.required),
                ra_telefono: new FormControl('', Validators.required),
                ra_tipo: new FormControl('cess'),
            }),
        });
    }

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private nazioniSrv: NazioniService,
        private serramentistaSrv: SerramentistaService,
        private msgService: MessageService,
        private route: ActivatedRoute,
        private utcDatePipe: UtcDatePipe,
        private _route: Router,
        private messageSrv: MessageService,
    ) {
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id') ?? '0';
        this.breadcrumbService.setItems([
            {
                label: `Dati Cessionario finale`,
            },
        ]);
        this.GeneraSerramentista();
        this.refreshNazioni();
        this.refreshRegioni();
        this.refreshSerramentista(this.id);
        Utility.updateFormValidity(this.frmSerramentista);
    }

    refreshSerramentista(idCliente: string): void {
        this.serramentistaSrv.getCessionario(idCliente).subscribe((res) => {
            this.serramentista = { ...res };
            if (!_.isEmpty(this.serramentista)) {
                this.serramentista.legale.srl_data_nascita = this.utcDatePipe.transform(
                    this.serramentista.legale.srl_data_nascita, 'yyyy-MM-dd', 'dd/MM/yyyy');
                this.frmSerramentista.patchValue(this.serramentista);
                this.serr_regione.inputEL.nativeElement.value = this.serramentista.serr_regione;
                this.nazioniSrv.getCittaByRegione(this.serramentista.serr_regione).subscribe((res) => {
                    this.citta = [...res.data];
                    this.serr_citta.inputEL.nativeElement.value = this.serramentista.serr_citta;
                });
            }
        });
    }

    refreshNazioni(): void {
        this.nazioniSrv
            .getNazioni()
            .subscribe((res) => (this.nazioni = [...res.data]));
    }

    refreshRegioni(): void {
        this.nazioniSrv
            .getRegioni()
            .subscribe((res) => (this.regioni = [...res.data]));
    }

    refreshCitta(ev, key: string, section: string = ''): void {
        const regione: string = ev.value;
        if (_.isEmpty(regione)) {
            return;
        }
        this.nazioniSrv.getCittaByRegione(regione).subscribe((res) => {
            this.citta = [...res.data];
        });
        this.frmSerramentista.controls[key].setValue(regione);
        this.serr_regione.inputEL.nativeElement.value = regione;
    }

    Submit(): void {
        const data = { ...this.frmSerramentista.value };
        this.convertiDateSubmit(data);
        this.serramentistaSrv.addCessionario(data).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'danger',
                summary: `Cliente`,
                detail: 'Cessionario Finale Salvato!',
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            if (this.id == 'nuovo') {
                this._route.navigate(['CessionarioFinaleList'])
            } else {
                this.refreshSerramentista(this.id)
            };
        });
    }

    convertiDateSubmit = (data: Serramentista) => {
        data.legale.srl_data_nascita = this.utcDatePipe.transform(
            data.legale.srl_data_nascita,
            'dd/MM/yyyy',
            'yyyy-MM-dd'
        );
    };

    search(ev, type): void {
        const query: string = ev.query;
        switch (true) {
            case type === 'regioni':
                this.regioniSuggestion = [
                    ...this.regioni.filter(
                        (r: Regioni) =>
                            r.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
            case type === 'citta':
                this.cittaSuggestion = [
                    ...this.citta.filter(
                        (c: Citta) =>
                            c.value
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) !== -1
                    ),
                ];
                break;
        }
    }

    selectProvincia(ev: Citta, key: string, section: string = ''): void {
        this.frmSerramentista.controls[key].setValue(ev.value);
        this.serr_citta.inputEL.nativeElement.value = ev.value;
        this.frmSerramentista.controls['serr_provincia'].setValue(ev.provincia);
    }

    resetDatiAbitazione(): void {
        this.frmSerramentista.controls.serr_regione.reset();
        this.frmSerramentista.controls.serr_citta.reset();
        this.frmSerramentista.controls.serr_provincia.reset();
    }

    resetDatiResidenza(): void {
        this.frmSerramentista.controls.serr_regione.reset();
        this.frmSerramentista.controls.serr_citta.reset();
        this.frmSerramentista.controls.serr_provincia.reset();
    }

}
