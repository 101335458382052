
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Scadenza } from 'src/app/_interfaces/serramentista';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScadenzeService } from 'src/app/_services/scadenze.service';

@Component({
  selector: 'app-scadenze',
  templateUrl: './scadenze.component.html',
  styleUrls: ['./scadenze.component.scss']
})
export class ScadenzeComponent implements OnInit {
  scad: Scadenza[] = [];
  scadForm: FormGroup;
  displayAddDialog: boolean = false;
  today: Date = new Date();

  @Input() id!: string;


  tipologiaAllegato: { label: string; value: string }[] = [
    { label: 'Visura Camerale', value: 'Visura Camerale' },
    { label: 'documento L.R.', value: 'documento L.R.' }
  ];


  constructor(
    private fb: FormBuilder,
    private scadService: ScadenzeService,
    private messageSrv: MessageService,
    private confirmService: ConfirmationService,
  ) { this.GeneraScadenze() }

  ngOnInit(): void {
    this.refreshScadenze(this.id);
  }

  GeneraScadenze(): void {
    this.scadForm = this.fb.group({
      doc_id: [0],
      doc_serr_id: [0],
      doc_tipologia: ['', Validators.required],
      doc_scadenza: ['', Validators.required],
      doc_segnalato: ['', Validators.required],
      doc_note: ['']
    });
  }

  isExpired(scadenzaDate: any): boolean {
    if (!scadenzaDate) return false;
    const parsedDate = new Date(scadenzaDate);
    return parsedDate < this.today;
  }

  refreshScadenze(id: string,): void {
    this.scadService.getByIdSerr(id).subscribe((res) => {
      this.scad = [...res.data];
    });
  }

  showAddDialog() {
    this.displayAddDialog = true;
  }

  hideAddDialog() {
    this.displayAddDialog = false;
  }

  // Aggiungi un nuovo membro alla lista
  addMember() {
    if (this.scadForm.valid) {
      if (this.isDuplicate(this.scadForm.get('doc_tipologia')?.value)) {
        this.hideAddDialog(); // Chiudi il dialog
        this.messageSrv.add({
          key: "tst",
          severity: "error",
          summary: `Messaggio`,
          detail: "danger",
        });
        return;
      }

      this.scadForm.controls['doc_serr_id'].setValue(this.id);
      this.scadService.add(this.scadForm.value).subscribe((res) => {
        this.handleResponse(res);
        this.scadForm.reset();
        this.refreshScadenze(this.id)
      });
      this.hideAddDialog(); // Chiudi il dialog
    }
  }

  isDuplicate(docTipologia: string): boolean {
    return this.scad.some(item => item.doc_tipologia === docTipologia);
  }

  EditMember(scadenza: any) {
    scadenza.doc_serr_id = this.id;
    this.scadService.add(scadenza).subscribe((res) => {
      this.handleResponse(res);
      this.refreshScadenze(this.id)
    });
  }

  DelMember(id: number) {
    this.confirmService.confirm({
      header: `Elimina Scadenza`,
      message: 'Sicuro di Eseguire l\'operazione di Cancellazione?',
      icon: 'pi  pi-question-circle',
      accept: () => {
        this.scadService.del(id).subscribe((res) => {
          this.handleResponse(res);
          this.refreshScadenze(this.id)
        });
      },
    });
  }

  handleResponse(res: any) {
    this.messageSrv.add({
      key: "tst",
      severity: res.res === "ok" ? "success" : "error",
      summary: `Messaggio`,
      detail: res.message,
    });
  }

}
