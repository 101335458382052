import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Credenziali, RecoveryPassword} from '../_interfaces/utente';
import {AuthService} from '../_services/auth.service';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-app.recover',
    templateUrl: './app.recover.component.html'
})

export class RecoverComponent implements OnInit {

    hashLink: string = '';
    email: string = '';
    id: string = '';

    formLogin: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private msgService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.hashLink = atob(this.route.snapshot.paramMap.get('hash'));
        let splitHash = this.hashLink.split('&');
        this.email = splitHash[0];
        this.id = splitHash[1];

        this.formLogin = new FormGroup({
            id: new FormControl(this.id),
            email: new FormControl(this.email),
            password: new FormControl("", Validators.required),
            password_copy: new FormControl("", [RecoverComponent.checkPasswords])
        });
    }

    public static checkPasswords = (c: AbstractControl): ValidationErrors => {
        const formLogin = c.root;
        if (!formLogin) {
            return null;
        }
        const password = formLogin.get('password')?.value ?? null;
        const confirmPassword = formLogin.get('password_copy')?.value ?? null;
        return password === confirmPassword ? null : {notSame: true}
    }

    submit(): void {
        const credenziali: RecoveryPassword = {...this.formLogin.value};
        credenziali.password = CryptoJS.SHA256(credenziali.password).toString();
        this.authService.recoveryPassword(credenziali).subscribe(
            (res) => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: `${res.message}`,
                });
            }
        );
    }

    tornaLogin(): void {
        this.router.navigateByUrl('/login');
    }
}
