import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Multibeneficiario } from '../_interfaces/multibeneficiario';

@Injectable({
  providedIn: 'root'
})
export class MiltibeneficiariService {

  private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    getMbByPrev(id: string): Observable<{ data: Multibeneficiario[] }> {
      return this.http.get(`${this.baseUrl}/multibeneficiari/by_preventivo/${id}`).pipe(
        map((res: { data: Multibeneficiario[] }) => res)
      );
    }

    getMbByLav(id: string): Observable<{ data: Multibeneficiario[] }> {
      return this.http.get(`${this.baseUrl}/multibeneficiari/by_lavoro/${id}`).pipe(
        map((res: { data: Multibeneficiario[] }) => res)
      );
    }

    addMultibeneficiario(mb: Multibeneficiario): Observable<{res: string, message: string }> {
      return this.http.post(`${this.baseUrl}/multibeneficiari/add`, mb ).pipe(
        map((res: { res: string, message: string }) => res));
    }

    

}
