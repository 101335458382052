import { Pipe, PipeTransform } from '@angular/core';
import { format, parse, isValid, parseISO } from 'date-fns';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(_value: any, _formatIn: string = 'dd/MM/yyyy', _formatOut: string = 'yyyy-MM-dd'): string {
    if(!_value){
      return null;
    }
    const d = parseISO(_value);
    if(isValid(d)){
      return format(d, _formatOut);
    }
    return format((typeof _value === 'string') ? parse(_value, _formatIn, new Date()): _value, _formatOut);
  }

}
