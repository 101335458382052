import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Nazioni, Regioni, Citta } from '../_interfaces/nazioni';

@Injectable({
  providedIn: 'root'
})
export class NazioniService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getNazioni(): Observable<{ data: Nazioni[]}> {
    return this.http.get(`${this.baseUrl}/nazioni`).pipe(
      map((res: { data: Nazioni[]}) => res)
    );
  }

  getRegioni(): Observable<{ data: Regioni[]}> {
    return this.http.get(`${this.baseUrl}/citta/regioni`).pipe(
      map((res: { data: Regioni[]}) => res)
    );
  }

  getCittaByRegione(regione: string): Observable<{ data: Citta[]}> {
    return this.http.get(`${this.baseUrl}/citta/${regione}`).pipe(
      map((res: { data: Citta[]}) => res)
    );
  }

}
