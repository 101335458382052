import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import * as FileSaver from "file-saver";
import { Preventivo } from "src/app/_interfaces/lavori";
import { ExportPreventiviExcel } from "src/app/_interfaces/list-type";
import { Preventivi } from "src/app/_interfaces/preventivi";
import { LavoriService } from "src/app/_services/lavori.service";
import { PreventiviService } from "src/app/_services/preventivi.service";
import { SerramentistaService } from "src/app/_services/serramentista.service";
import { AppBreadcrumbService } from "src/app/app.breadcrumb.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-preventivi-export",
    templateUrl: "./preventivi-export.component.html",
    styleUrls: ["./preventivi-export.component.scss"],
})
export class PreventiviExportComponent implements OnInit {
    preventivi: Preventivi[];
    SelecExpExcel: Preventivi[] = [];

    environment = environment;
    ExpExcel: ExportPreventiviExcel[];

    ruolo: string;
    // exportColumns: any[];
    cols: any[];

    cessionarioFinale: { label: string; value: number }[] = [];

    constructor(
        private offerteSrv: LavoriService,
        private preventiviSrv: PreventiviService,
        private datePipe: DatePipe,
        private breadcrumbService: AppBreadcrumbService,
        private SerramentistaSrv: SerramentistaService
    ) { }

    ngOnInit(): void {
        this.ruolo = sessionStorage.getItem("ruolo");
        this.refreshCessionariFinali();
    }

    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [];
            [...res.data].forEach((item) => {
                this.cessionarioFinale.push({
                    label: item.serr_ragsoc,
                    value: item.serr_id,
                });
            });

            this.breadcrumbService.setItems([{ label: "Export Preventivi" }]);
            this.refreshLavoriPratiche();
        });
    }

    refreshLavoriPratiche(): void {
        this.preventivi = [];
        if (this.ruolo != "SE") {
            this.preventiviSrv
                .getPreventivoStato(0, this.ruolo)
                .subscribe((res) => {
                    this.preventivi = [...res.data];
                    this.ConvertiPerExport();
                });
        } else {
            this.preventiviSrv
                .getPreventivoStatoByUser(0, sessionStorage.getItem("id"),
                    this.ruolo
                )
                .subscribe((res) => {
                    this.preventivi = [...res.data];
                    this.ConvertiPerExport();
                });
        }
    }

    exportExcel(): void {
        import("xlsx").then((xlsx) => {
            const exp: any[] =
                this.SelecExpExcel.length > 0
                    ? this.SelecExpExcel
                    : this.ExpExcel;
            const worksheet = xlsx.utils.json_to_sheet(exp);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "Preventivi");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName +
            "_export_" +
            this.datePipe
                .transform(new Date(), "yyyyMMddHHmmss")
                .toString() +
            EXCEL_EXTENSION
        );
    }

    ConvertiPerExport(): void {
        this.ExpExcel = [];
        let rigaEXcel: any = {};
        // let rigaEXcel: ExportPreventiviExcel = {} as ExportPreventiviExcel;

        this.preventivi.sort((a, b) =>
            a.prev_data_aggiunta > b.prev_data_aggiunta ? -1 : 1
        );

        this.preventivi.forEach((item) => {
            let prObj: Preventivo = JSON.parse(item.prev_preventivo.toString());
            rigaEXcel.Tipo_detrazione = item.prev_codice.includes("_DD_") ? "Detrazione Diretta" : "Sconto in Fattura";
            rigaEXcel.Codice = item.prev_codice;
            rigaEXcel.Serramentista = item.name;
            rigaEXcel.Stato =
                item.prev_stato == 1
                    ? "Elaborato"
                    : item.prev_stato == 2
                        ? "Presentato"
                        : item.prev_stato == 3
                            ? "Da Inserire"
                            : item.prev_stato == 4
                                ? "Approvato"
                                : "KO";


            rigaEXcel.Giorno_Modifica = new Date(item.prev_data_modifica)
                .getDate()
                .toString();
            rigaEXcel.Mese_Modifica = (
                new Date(item.prev_data_modifica).getMonth() + 1
            ).toString();
            rigaEXcel.Anno_Modifica = new Date(item.prev_data_modifica)
                .getFullYear()
                .toString();


            rigaEXcel.Cliente = item.cl_cognome + " " + item.cl_nome;
            rigaEXcel.Provincia = item.ab_provincia;

            if (item.prev_tipo_detrazione == "Bonus Casa" || item.prev_tipo_detrazione == "BC_SI" || item.prev_tipo_detrazione == "BC_AI") {
                rigaEXcel.Tipologia_bonus = 'BC'

            }
            else if (item.prev_tipo_detrazione == "BA_AI") {
                rigaEXcel.Tipologia_bonus = 'BA'
            }
            else if (item.prev_tipo_detrazione == "ECO Bonus" || item.prev_tipo_detrazione == "SSCO" || item.prev_tipo_detrazione == "SI") {
                rigaEXcel.Tipologia_bonus = 'EB'
            } else {
                rigaEXcel.Tipologia_bonus = item.prev_tipo_detrazione
            }

            rigaEXcel.ID_intervento = item.prev_tipologia;

            rigaEXcel.Tipologia_di_intervento = item.tin_nome;

            if (this.cessionarioFinale.length != 0) {
                // if (item.lat_cessionario) {
                //     rigaEXcel.Cessionario_finale = this.cessionarioFinale.find((x) => x.value == item.lat_cessionario)?.label;
                // }
                // if (rigaEXcel.Cessionario_finale =='' || rigaEXcel.Cessionario_finale == undefined || rigaEXcel.Cessionario_finale == null){

                rigaEXcel.Cessionario_finale = this.cessionarioFinale.find((x) => x.value == item.prev_cessionario).label;
                // console.log("codice -> ", item.prev_codice, " idc -> ", item.prev_cessionario , " label -> " , this.cessionarioFinale.find((x) => x.value == item.prev_cessionario)?.label)
                // }
            } else {
                rigaEXcel.Cessionario_finale = "Schueco PWS Italia";
            }

            let vendita_infissi = isNaN(Number(item.prev_vendita_infissi)) ? 0 : this.roundN(Number(item.prev_vendita_infissi));
            let posa_opera = isNaN(Number(item.prev_posa_opera)) ? 0 : this.roundN(Number(item.prev_posa_opera));
            // let beni_significativi = isNaN(Number(item.prev_beni_significativi)) ? 0 : this.roundN(Number(item.prev_beni_significativi));
            let altri_beni = isNaN(Number(item.prev_altri_beni)) ? 0 : this.roundN(Number(item.prev_altri_beni));
            let altre_opere = isNaN(Number(item.prev_altre_opere)) ? 0 : this.roundN(Number(item.prev_altre_opere));
            let prestazioni_prof = isNaN(Number(item.prev_prestazioni_prof)) ? 0 : this.roundN(Number(item.prev_prestazioni_prof));

            const imponibile_serramentista = item.prev_codice.includes("_DD_") ?
                vendita_infissi + posa_opera + altre_opere + altri_beni + prestazioni_prof :
                isNaN(prObj.imponibileSerramentista) ? 0 : this.roundN(prObj.imponibileSerramentista);
            rigaEXcel.Imponibile_fornitore = this.roundN(imponibile_serramentista);

            var speseTecniche = 0
            if (item.prev_codice.includes("_DD_")) {
                speseTecniche = Number(prObj.fattura.totale.imponibile) - Number(imponibile_serramentista)
            } else {
                speseTecniche = Number(prObj.speseTecniche)
            }

            var imponibile_totale = 0
            if (item.prev_codice.includes("_DD_")) {
                imponibile_totale = prObj.fattura.totale.imponibile;
                // speseTecniche = Number(prObj.fattura.totale.imponibile) - Number(imponibile_serramentista)
            } else {
                // speseTecniche = isNaN(prObj.speseTecniche) ? 0 : this.roundN(prObj.speseTecniche);
                imponibile_totale = speseTecniche + imponibile_serramentista;
            }
            rigaEXcel.Spese_tecniche = this.roundN(speseTecniche);

            rigaEXcel.imponibile_totale = isNaN(imponibile_totale) ? 0 : this.roundN(imponibile_totale);


            const iva_tot =
                item.prev_codice.includes("_DD_")
                    ? Number(prObj.fattura.totale.iva_10) + Number(prObj.fattura.totale.iva_22)
                    : Number(item.prev_importo.toString()) - (speseTecniche + imponibile_serramentista);
            rigaEXcel.iva_totale = isNaN(iva_tot) ? 0 : this.roundN(iva_tot);

            rigaEXcel.Totale_fattura_Iva_compresa = this.roundN(Number(item.prev_importo.toString()));
            rigaEXcel.Costi_di_attualizzazione = prObj.costiAttuazione == undefined ? 0 :
                this.roundN(prObj.costiAttuazione);

            if (item.prev_codice.includes("_DD_")) {
                rigaEXcel.Contributo_spettante = this.roundN(Number(item.prev_imp_detraibile_enea))
            } else {
                if (prObj.contributoSpettante <= 0) {
                    rigaEXcel.Contributo_spettante = prObj.contributoSpettante == undefined ? 0 :
                        this.roundN((-1 * Number(prObj.contributoSpettante)));
                } else {
                    rigaEXcel.Contributo_spettante = prObj.contributoSpettante == undefined ? 0 :
                        this.roundN(prObj.contributoSpettante);
                }
            }

            let Importo_lavori_a_carico_del_cliente = 0
            item.prev_codice.includes("_DD_")
                ? Importo_lavori_a_carico_del_cliente = this.roundN(Number(item.prev_importo.toString()))
                : Importo_lavori_a_carico_del_cliente = prObj.ImportoLavoriCaricoCliente == undefined ? 0
                    : this.roundN(prObj.ImportoLavoriCaricoCliente);

            rigaEXcel.Importo_lavori_a_carico_del_cliente = Importo_lavori_a_carico_del_cliente;

            rigaEXcel.Importo_non_detraibile =
                item.prev_codice.includes("_DD_")
                    ? this.roundN(Number(prObj.calcolo_massimali.imp_non_detraibile))
                    : this.roundN(prObj.importoNONDetraibileACaricoCliente);



            if (item.prev_codice.includes("_DD_")) {
                rigaEXcel.Importo_detraibile = this.roundN(Number(item.prev_imp_detraibile_enea))
            }
            else {
                rigaEXcel.Importo_detraibile = this.roundN(Number(item.prev_importo)) -
                    this.roundN(Number(prObj.importoNONDetraibileACaricoCliente))
            }


            rigaEXcel.Totale_dovuto_dal_cliente = item.prev_codice.includes("_DD_")
                ? this.roundN(Number(item.prev_importo.toString())) : this.roundN(prObj.totaleDovutoCliente);
            rigaEXcel.Mq_Installati = this.roundN(Number(item.prev_mq_installati) +
                Number(item.prev_mq_installati_co));
            rigaEXcel.Asseverazione = item.prev_asseverazione;

            if (item.prev_asseverazione === "NO") {
                rigaEXcel.AsseverazioneCosto = 0;
            } else {
                rigaEXcel.AsseverazioneCosto = this.roundN(
                    (Number(imponibile_serramentista) *
                        Number(item.lat_perc_asseverazione)) /
                    100 +
                    Number(item.lat_costo_asseverazione)
                );
            }

            rigaEXcel.Visto = item.prev_visto;

            if (item.prev_visto === "NO") {
                rigaEXcel.VistoCosto = 0;
            } else {
                rigaEXcel.VistoCosto = this.roundN(
                    (Number(imponibile_serramentista) *
                        Number(item.lat_perc_visto)) /
                    100 +
                    Number(item.lat_costo_visto)
                );
            }

            rigaEXcel.Pratica_Enea = item.prev_praticaenea == true ? 'SI' : 'NO';
            rigaEXcel.Costo_Enea = this.roundN(Number(item.prev_costoenea));

            rigaEXcel.Account = item.account;

            this.ExpExcel.push(rigaEXcel);
            rigaEXcel = {};
            // rigaEXcel = {} as ExportPreventiviExcel;
        });
    }

    roundN(num: number): number {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
}
