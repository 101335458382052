import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ArchivioCategoria } from '../_interfaces/list-type';

@Injectable({
  providedIn: 'root'
})
export class ArchivioCategoriaService {
  private baseUrl: string = environment.baseUrl;
  
  constructor(private http: HttpClient) { }

  getArchivioCaterorie(): Observable<{ data: ArchivioCategoria[] }> {
    return this.http.get(`${this.baseUrl}/archivio/get_categorie`).pipe(
      map((res: { data: ArchivioCategoria[] }) => res));
  }


  addArchivioCategoria(archivioCategoria: ArchivioCategoria): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/archivio/add_categoria`, archivioCategoria).pipe(
      map((res: { res: string, message: string }) => res));
  }

}
