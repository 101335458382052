import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
      <div class="layout-footer">
         <div style="text-align:center;">
          <span style="color: black;" ><a style="color: black;" href="https://www.officinerinnovabili.it" target="_blank">www.officinerinnovabili.it</a> <br>
          Via Ugo Ojetti, 145 - 00137 - Roma</span>
          </div> 
      </div>    `
})
export class AppFooterComponent {

}
