import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crediti-page',
  templateUrl: './crediti-page.component.html'
})
export class CreditiPageComponent implements OnInit {
  user_id: string;
  constructor() { }

  ngOnInit(): void {
    this.user_id = sessionStorage.getItem('id')
  }

}
