import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Preventivi, PreventiviStato } from "../_interfaces/preventivi";
import { StepPreventivi } from "../_interfaces/stepPreventivi";

@Injectable({
    providedIn: "root",
})
export class PreventiviService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {}

    getListaPreventivi(): Observable<{ data: Preventivi[] }> {
        return this.http
            .get(`${this.baseUrl}/preventivi`)
            .pipe(map((res: { data: Preventivi[] }) => res));
    }

    getListaPreventiviByUser(
        userId: number
    ): Observable<{ data: Preventivi[] }> {
        return this.http
            .get(`${this.baseUrl}/preventivi/user/` + userId)
            .pipe(map((res: { data: Preventivi[] }) => res));
    }

    getPreventivoStato(
        stato: number,
        ruolo: string
    ): Observable<{ data: Preventivi[] }> {
        return this.http
            .get(`${this.baseUrl}/preventivi/stato/${stato}/${ruolo}`)
            .pipe(map((res: { data: Preventivi[] }) => res));
    }

    getPreventivoStatoByUser(
        stato: number,
        idUtente: string,
        ruolo: string
    ): Observable<{ data: Preventivi[] }> {
        return this.http
            .get(
                `${this.baseUrl}/preventivi/user_stato/${idUtente}/${stato}/${ruolo}`
            )
            .pipe(map((res: { data: Preventivi[] }) => res));
    }

    getPreventivoById(idPreventivo: number): Observable<Preventivi> {
        return this.http.get(`${this.baseUrl}/preventivi/` + idPreventivo).pipe(
            map((res: { data: Preventivi }) => {
                return res.data[0];
            })
        );
    }

    salvaPreventivo(
        preventivo: Preventivi
    ): Observable<{ res: string; message: string; id: number }> {
        return this.http
            .post(`${this.baseUrl}/preventivi/add`, preventivo)
            .pipe(
                map((res: { res: string; message: string; id: number }) => res)
            );
    }

    getLogStati(idPreventivo: number): Observable<{ data: PreventiviStato[] }> {
        return this.http
            .get(`${this.baseUrl}/preventivi/log/${idPreventivo}`)
            .pipe(map((res: { data: PreventiviStato[] }) => res));
    }

    getStepPreventivi(
        idPreventivo: number
    ): Observable<{ data: StepPreventivi[] }> {
        return this.http
            .get(`${this.baseUrl}/preventivi/step_by_prev/` + idPreventivo)
            .pipe(map((res: { data: StepPreventivi[] }) => res));
    }

    upCodiceManuale(id: number, codice:string): Observable<{ res: string, message: string }> {
        let cod : {"prev_id": number, "prev_codice": string} = {"prev_id":id, "prev_codice": codice}
        return this.http.post(`${this.baseUrl}/preventivi/up_codice_manuale`, cod).pipe(
            map((res: { res: string, message: string }) => res));
    }

    addStep(
        pstep_id: string,
        pstep_preventivo: string,
        pstep_step: string,
        pstep_numero: string,
        pstep_nome: string,
        pstep_tipo: string,
        pstep_data: string,
        pstep_note: string
    ): Observable<{ res: string; message: string; nome_file: string }> {
        return this.http
            .post(`${this.baseUrl}/preventivi/add_step`, {
                pstep_id: pstep_id,
                pstep_preventivo: pstep_preventivo,
                pstep_step: pstep_step,
                pstep_numero: pstep_numero,
                pstep_nome: pstep_nome,
                pstep_tipo: pstep_tipo,
                pstep_data: pstep_data,
                pstep_note: pstep_note,
            })
            .pipe(
                map(
                    (res: {
                        res: string;
                        message: string;
                        nome_file: string;
                    }) => res
                )
            );
    }

    upload(
        uplo: File,
        pstep_id: string,
        pstep_preventivo: string,
        pstep_step: string,
        pstep_numero: string,
        pstep_nome: string,
        pstep_tipo: string,
        pstep_data: string,
        pstep_note: string
    ): Observable<{ res: string; message: string; nome_file: string }> {
        const formData: FormData = new FormData();
        formData.append("file", uplo);
        formData.append("pstep_id", pstep_id);
        formData.append("pstep_preventivo", pstep_preventivo);
        formData.append("pstep_step", pstep_step);
        formData.append("pstep_numero", pstep_numero);
        formData.append("pstep_nome", pstep_nome);
        formData.append("pstep_tipo", pstep_tipo);
        formData.append("pstep_data", pstep_data);
        formData.append("pstep_note", pstep_note);
        return this.http
            .post(`${this.baseUrl}/preventivi/add_step`, formData)
            .pipe(
                map(
                    (res: {
                        res: string;
                        message: string;
                        nome_file: string;
                    }) => res
                )
            );
    }

    cambioStato(
        pstep_id: number,
        validato: number
    ): Observable<{ res: string; message: string }> {
        return this.http
            .post(`${this.baseUrl}/preventivi/up_validazione_step`, {
                pstep_id: pstep_id,
                pstep_validato: validato,
            })
            .pipe(map((res: { res: string; message: string }) => res));
    }

    InvioEmailCriticità(
        id: number
    ): Observable<{ res: string; message: string }> {
        return this.http
            .post(`${this.baseUrl}/preventivi/email_criticita`, { prev_id: id })
            .pipe(map((res: { res: string; message: string }) => res));
    }

    InvioEmailCaricamenti(
        id: number
    ): Observable<{ res: string; message: string }> {
        //Gli step per i preventivi è sempre 1
        return this.http
            .post(`${this.baseUrl}/preventivi/email_caricamenti`, {
                prev_id: id,
                step: 1,
            })
            .pipe(map((res: { res: string; message: string }) => res));
    }
}
