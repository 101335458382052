import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Abitazioni } from '../_interfaces/abitazioni';

@Injectable({
  providedIn: 'root'
})
export class AbitazioniService {
  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAbitazioni(idCliente: string): Observable<{ data: Abitazioni[] }> {
    return this.http.get(`${this.baseUrl}/abitazioni/cliente/${idCliente}`).pipe(
       map((res: { data: Abitazioni[] }) => res)
      //map((res: { data: Abitazioni }) => {
      ///  res.data[0].cnt_offerte_approvate = res.data[0].cnt_offerte_approvate[0] ?? {};
      //  return res.data[0];
      //})
    );
  }

  addAbitazioni(abitazione: Abitazioni): Observable<{ res: string, message: string, id: number }> {
    return this.http.post(`${this.baseUrl}/abitazioni/add`, abitazione).pipe(
      map((res: { res: string, message: string, id: number }) => res));
  }

  delAbitazioni(idAbitazione: number, azione: boolean): Observable<{ res: string, message: string }> {
    return this.http.post(`${this.baseUrl}/abitazioni/del`, { ab_id: idAbitazione, ab_cancellata: azione }).pipe(
      map((res: { res: string, message: string }) => res));
  }

}
