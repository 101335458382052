import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private baseUrl: string = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  upload(uplo: File, nomeFile: string, docStep: string, docNum: string): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    formData.append("codice", nomeFile);
    formData.append("step", docStep);
    formData.append("doc", docNum);
    return this.httpClient.post(`${this.baseUrl}/upload/doc_lavoro`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

  uploadAllegatoSeramentista(uplo: File): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    return this.httpClient.post(`${this.baseUrl}/upload/allegato`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

  uploadAllegatoArchivio(uplo: File): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    return this.httpClient.post(`${this.baseUrl}/upload/archivio`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

  uploadAdmin(uplo: File, codice: string, step: string, tipo: string): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    formData.append("codice", codice);
    formData.append("step", step);
    formData.append("tipo", tipo);
    return this.httpClient.post(`${this.baseUrl}/upload/admin_doc_step`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

  uploadAggregatiStep5(uplo: File, nomeFile: string, step: string, num: string, serrID:string): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    formData.append("nome", nomeFile);
    formData.append("step", step);
    formData.append("numero", num);
    formData.append("serr", serrID);
    return this.httpClient.post(`${this.baseUrl}/upload/doc_lavoro_aggregato`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

  uploadStep2(uplo: File, idLavoro: string,): Observable<{ res: string, message: string, nome_file: string }> {
    const formData: FormData = new FormData();
    formData.append("file", uplo);
    formData.append("lavoro", idLavoro);
    return this.httpClient.post(`${this.baseUrl}/upload/file_step2`, formData).pipe(
      map((res: { res: string, message: string, nome_file: string }) => res)
    );
  }

}
