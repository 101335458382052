import { Component, Input, OnInit } from '@angular/core';
import { MiltibeneficiariService } from "src/app/_services/miltibeneficiari.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Multibeneficiario } from 'src/app/_interfaces/multibeneficiario';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-multibeneficiario',
  templateUrl: './multibeneficiario.component.html',
  styleUrls: ['./multibeneficiario.component.scss']
})
export class MultibeneficiarioComponent implements OnInit {
  multiBn: Multibeneficiario[];
  newMemberForm: FormGroup;
  displayAddDialog: boolean = false;

  @Input() id!: string;
  @Input() tipo!: Number;
  @Input() mMultiB!: boolean;
  @Input() ruolo!: string;



  constructor(
    private fb: FormBuilder,
    private mbService: MiltibeneficiariService,
    private messageSrv: MessageService,
    private confirmService: ConfirmationService,
  ) { this.GeneraMembro() }

  ngOnInit(): void {
    this.refreshMultibeneficiari(this.id);
  }

  GeneraMembro(): void {
    this.newMemberForm = this.fb.group({
      mb_id: [0],
      mb_prev_id: [0],
      mb_lav_id: [0],
      mb_canc: [0],
      mb_nome: ['', Validators.required],
      mb_cognome: ['', Validators.required],
      mb_data_nascita: ['', Validators.required],
      mb_luogo_nascita: ['', Validators.required],
      mb_residenza: ['', Validators.required],
      mb_cf: ['', [Validators.required, Validators.pattern('[A-Za-z0-9]{16}')]] // Ad esempio, un codice fiscale di 16 caratteri
    });
  }

  refreshMultibeneficiari(id: string,): void {
    this.mbService.getMbByPrev(id).subscribe((res) => {
      this.multiBn = [...res.data];
    });
  }

  showAddDialog() {
    this.displayAddDialog = true;
  }

  hideAddDialog() {
    this.displayAddDialog = false;
  }

  // Aggiungi un nuovo membro alla lista
  addMember() {
    console.log('this.newMemberForm ', this.newMemberForm)
    if (this.newMemberForm.valid) {
      this.newMemberForm.controls['mb_prev_id'].setValue(this.id);
      this.mbService.addMultibeneficiario(this.newMemberForm.value).subscribe((res) => {
        this.handleResponse(res);
        this.refreshMultibeneficiari(this.id)
      });
      this.newMemberForm.reset();
      this.hideAddDialog(); // Chiudi il dialog
    }
  }

  EditMember(mem: any, azione: string) {
    mem.mb_prev_id = this.id;
    if (azione == "UPD") {
      this.mbService.addMultibeneficiario(mem).subscribe((res) => {
        this.handleResponse(res);
        this.refreshMultibeneficiari(this.id)
      });
    } else if (azione == "DEL") {
      mem.mb_canc = 1;
      this.confirmService.confirm({
        header: `Elimina Multibeneficiario`,
        message: 'Sicuro di Eseguire l\'operazione di Cancellazione?',
        icon: 'pi  pi-question-circle',
        accept: () => {
          this.mbService.addMultibeneficiario(mem).subscribe((res) => {
            this.handleResponse(res);
            this.refreshMultibeneficiari(this.id)
          });
        },
      });
    }
  }

  handleResponse(res: any) {
    this.messageSrv.add({
      key: "tst",
      severity: res.res === "ok" ? "success" : "error",
      summary: `Messaggio`,
      detail: res.message,
    });
  }


}
