import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Credito } from "src/app/_interfaces/credito";
import { CreditoService } from "src/app/_services/credito";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-crediti",
    templateUrl: "./crediti.component.html",
    styleUrls: ["./crediti.component.scss"],
})
export class CreditiComponent implements OnInit {
    modalStato: boolean;
    credit: Credito[];
    frmCrediti: FormGroup;
    ruolo: string;
    environment = environment;
    totalCredit: number;
    ko: number;

    @Input() serr_id?: string;
    @Input() user_id: string;

    constructor(
        private crediti: CreditoService,
        private msgService: MessageService,
        private confirmService: ConfirmationService
    ) {
        this.ruolo = sessionStorage.getItem("ruolo");
    }

    TipoCrediti: { label: string; value: any }[] = [
        { label: "Carico", value: "C" },
        { label: "Scarico", value: "S" },
    ];

    ngOnInit(): void {
        this.modalStato = false;
        this.creaformCrediti();
        this.getCrediti();
        this.getKo();
    }

    creaformCrediti(): void {
        this.frmCrediti = new FormGroup({
            uc_id: new FormControl(0),
            uc_user_id: new FormControl(0),
            uc_serr_id: new FormControl(0),
            uc_tipo: new FormControl(""),
            uc_tipo_movimento: new FormControl("C", Validators.required),
            uc_preventivo_id: new FormControl(0),
            uc_lavoro_id: new FormControl(0),
            uc_qta: new FormControl(0),
            uc_note: new FormControl(""),
        });
    }

    getCrediti(): void {
        this.crediti.getCrediti(Number(this.serr_id)).subscribe((res) => {
            this.credit = [...res.data];
            this.calcolaTotCrediti();
        });
    }
    delCredito(idCredito:number): void {
        this.confirmService.confirm({
            header: `Elimina Credito`,
            message: 'Sicuro di Eseguire l\'operazione di Cancellazione?',
            icon: 'pi  pi-question-circle',
            accept: () => {
                this.crediti.delCrediti(idCredito).subscribe((res) => {
                this.msgService.add({
                  key: 'tst',
                  severity: res.res === 'ok' ? 'success' : 'error',
                  summary: `Elimina`,
                  detail: res.message,
                  life: 8000 // Tempo in millisecondi (10 secondi)
                });
                this.getCrediti();
              })
            },
          });
    }

    getKo(): void {
        this.ko = 0;
        this.crediti.getKo(Number(this.serr_id)).subscribe((res) => {
            this.ko = res.cnt_ko;
        });
    }

    openModal(): void {
        this.creaformCrediti();
        this.frmCrediti.controls["uc_user_id"].setValue(
            sessionStorage.getItem("id")
        );
        this.frmCrediti.controls["uc_serr_id"].setValue(this.serr_id);
        this.modalStato = true;
    }

    closeModal(): void {
        this.modalStato = false;
    }

    SubmitCrediti(): void {
        if (this.frmCrediti.controls["uc_tipo_movimento"].value == "S") {
            this.frmCrediti.controls["uc_qta"].setValue(
                this.frmCrediti.controls["uc_qta"].value * -1
            );
        }
        this.crediti.movCrediti(this.frmCrediti.value).subscribe(() => {
            this.msgService.add({
                key: "tst",
                severity: "success",
                summary: "Credito",
                detail: "Credito registrato!",
                life: 8000 // Tempo in millisecondi (10 secondi)
            });
            this.modalStato = false;
            this.getCrediti();
        });
    }

    calcolaTotCrediti() {
        let total = 0;
        for (let sale of this.credit) {
            total += Number(sale.uc_qta);
        }
        this.totalCredit = total;
    }
}
