import {Component, OnDestroy} from '@angular/core';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {AuthService} from './_services/auth.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})

export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;

    items: MenuItem[];
    ruolo: string;
    id: string;

    constructor(public breadcrumbService: AppBreadcrumbService, private authService: AuthService) {
        this.ruolo = sessionStorage .getItem('ruolo');
        this.id = sessionStorage.getItem('id');
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    doLogout = () => {
        this.authService.logout();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
