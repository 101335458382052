import {environment} from './../../environments/environment';
import {Observable} from 'rxjs';
import {
    CheckEmail,
    Credenziali,
    EmailResponse,
    LoginResponse,
    RecoveryPassword,
    RecoveryResponse
} from './../_interfaces/utente';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient, private router: Router) {
    }

    getUserId = () => {
        const id = sessionStorage.getItem('id');
        return id;
    }

    getUserName = () => {
        const name = sessionStorage.getItem('name');
        return name;
    }

    getUserRuolo = () => {
        const ruolo = sessionStorage.getItem('ruolo');
        return ruolo;
    }

    getToken = () => {
        const token = sessionStorage.getItem('access_token');
        return token;
    }

    isLoggedIn = () => {
        const token = sessionStorage.getItem('access_token');
        return !_.isEmpty(token);
    }

    doLogin(credenziali: Credenziali): Observable<LoginResponse> {
        return this.http.post(`${this.baseUrl}/utenti/login`, credenziali).pipe(
            map((res: LoginResponse) => {
                this.setSessionStorage(res);
                return res;
            })
        );
    }

    logout(): void {
        this.http.post(`${this.baseUrl}/utenti/logout`, {}).subscribe(() => {
            sessionStorage.clear();
            this.router.navigate(['/login']);
        });
    }

    checkEmail(checkEmail: CheckEmail): Observable<EmailResponse> {
        return this.http.post(`${this.baseUrl}/utenti/check`, checkEmail).pipe(
            map((res: EmailResponse) => {
                // this.setSessionStorage(res);
                return res;
            })
        );
    }

    recoveryPassword(credenziali: RecoveryPassword): Observable<RecoveryResponse> {
        return this.http.post(`${this.baseUrl}/utenti/recovery`, credenziali).pipe(
            map((res: RecoveryResponse) => {
                return res;
            })
        );
    }

    private setSessionStorage = (_data: any) => {
        Object.keys(_data).forEach((item) => {
            if (_.isObject(_data[item])) {
                this.setSessionStorage(_data[item]);
            } else {
                sessionStorage.setItem(item, _data[item]);
            }
        });
    }
}
