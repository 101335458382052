import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SerramentistaExport } from '../_interfaces/serramentista';
import { Utente, utenteAttr } from '../_interfaces/utente';

@Injectable({
    providedIn: 'root'
})
export class UtentiService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    getUtenti(ruolo: string): Observable<{ data: Utente[] }> {
        if (ruolo) {
            return this.http.get(`${this.baseUrl}/utenti/ruolo/${ruolo}`).pipe(
                map((res: { data: Utente[] }) => res)
            );
        } else {
            return this.http.get(`${this.baseUrl}/utenti/ruolo`).pipe(
                map((res: { data: Utente[] }) => res)
            );
        }
    }

    getExportUtenti(): Observable<{ data: SerramentistaExport[] }> {
        return this.http.get(`${this.baseUrl}/utenti/lista_utenti`).pipe(
            map((res: { data: SerramentistaExport[] }) => res));
    }

    getUtenteByID(id: string): Observable<{ data: Utente[] }> {
        return this.http.get(`${this.baseUrl}/utenti/utenti/${id}`).pipe(
            map((res: { data: Utente[] }) => res)
        );
    }

    getUtenteID(id: string): Observable<{ data: Utente[] }> {
        return this.http.get(`${this.baseUrl}/utenti/user/id/${id}`).pipe(
            map((res: { data: Utente[] }) => res)
        );
    }

    getUtentiCess(id: number): Observable<{ data: utenteAttr[] }> {
        return this.http.get(`${this.baseUrl}/users_attrs/cess/${id}`).pipe(
            map((res: { data: utenteAttr[] }) => res)
        );
    }

    addUtente(user: Utente): Observable<{ message: string; res: string }> {
        return this.http.post(`${this.baseUrl}/utenti/register`, user).pipe(map((res: { message: string; res: string }) => res));
    }

    updUtente(user: Utente): Observable<{ message: string; res: string }> {
        return this.http.post(`${this.baseUrl}/utenti/up`, user).pipe(map((res: { message: string; res: string }) => res));
    }


}
