import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class SerrGuard implements CanActivate {

    ROLES = {
        SuperAdmin: 2,
        Admin: 1,
        Serramentista: 0,
    };

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const ruolo = sessionStorage.getItem('ruolo').toString();
        const cntSerr = sessionStorage.getItem('count_serr');
        const cntAll = sessionStorage.getItem('count_all');

        if ((ruolo === 'SE') && ((cntSerr === '0') || ( Number(cntAll) < 4))) {
            return this.router.parseUrl('/serramentista/' + this.authService.getUserId());
        }

        return true;
    }

}
