import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Serramentista } from '../_interfaces/serramentista';

@Injectable({
    providedIn: 'root'
})

export class SerramentistaService {

    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    getSerramentista(id: string): Observable<Serramentista> {
        return this.http.get(`${this.baseUrl}/utenti/${id}/serr`).pipe(
            map((res: { data: Serramentista }) => {
                if (!_.isEmpty(res.data)) {
                    res.data[0].referente = res.data[0].referente[0] ?? {};
                    res.data[0].legale = res.data[0].legale[0] ?? {};
                    res.data[0].coordinate = res.data[0].coordinate[0] ?? {};
                    return res.data[0];
                } else {
                    return {}
                }
            })
        )
    }

    getCessionario(id: string): Observable<Serramentista> {
        return this.http.get(`${this.baseUrl}/serramentisti/cessionario_finale/${id}/cess`).pipe(
            map((res: { data: Serramentista }) => {
                if (!_.isEmpty(res.data)) {
                    res.data[0].referente = res.data[0].referente[0] ?? {};
                    res.data[0].legale = res.data[0].legale[0] ?? {};
                    res.data[0].coordinate = res.data[0].coordinate[0] ?? {};
                    return res.data[0];
                } else {
                    return {}
                }
            })
        )
    }

    getCessionarifinali(): Observable<{ data: Serramentista[] }> {
        return this.http.get(`${this.baseUrl}/serramentisti/cess`).pipe(
            map((res: { data: Serramentista[] }) => res)
        );
    }

    getCrediti(idSerr: string): Observable<{ data: [{ "saldo": string }] }> {
        return this.http.get(`${this.baseUrl}/users_crediti/saldo_by_serr/${idSerr}`).pipe(
            map((res: { data: [{ "saldo": string }] }) => res)
        );
    }

    addSerramentista(serramentista: Serramentista): Observable<{ res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/serramentisti/add`, serramentista).pipe(
            map((res: { res: string, message: string }) => res));
    }

    addCessionario(serramentista: Serramentista): Observable<{ res: string, message: string }> {
        return this.http.post(`${this.baseUrl}/serramentisti/addCessionario`, serramentista).pipe(
            map((res: { res: string, message: string }) => res));
    }



}
