import {FormGroup} from '@angular/forms';

export class Utility {
    public static updateFormValidity(_form: FormGroup) {
        Object.keys(_form.controls).forEach((c) => {
            const ctrl = _form.get(c);
            if (ctrl instanceof FormGroup) {
                this.updateFormValidity(ctrl as FormGroup);
            } else {
                ctrl.markAsTouched();
                ctrl.markAsDirty();
                ctrl.updateValueAndValidity({emitEvent: false});
            }
        });
    }
}
